<div id="chat-support" class="chat-support-icon text-right">
  <img
    width="90"
    height="85"
    class="mb-8 mr-8 responsiveBlckBox cursor-pointer"
    *ngIf="!showChat"
    [@animate]="{ value: '*', params: { delay: '100ms', x: '25px' } }"
    src="assets/icons/booking-reservation/chat-support.svg"
    (click)="iconClick()"
    alt=""
  />
  <div
    *ngIf="showChat"
    fxLayout="column"
    fxFlex="auto"
    class="w-430"
    [@animate]="{ value: '*', params: { delay: '100ms', x: '25px' } }"
  >
    <div
      fxLayout="row"
      fxFlex="100%"
      fxLayoutAlign="space-between center"
      class="p-8 custom-chatbox px-12"
    >
      <span class="logo-text h3 font-weight-800 font-size-14">
        Chat Support
      </span>
      <div
        class="cursor-pointer"
        (click)="closeIframe()"
        aria-label="Close chat support"
      >
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div
      class="fuse-white-bg boxshadow-sm"
      [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }"
    >
      <iframe
        [src]="iframeUrl"
        id="main_iframe"
        (load)="access()"
        title="Chat Support"
      ></iframe>
    </div>
  </div>
</div>
