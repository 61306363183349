import { MenuPermissionActions, MenuPermissionActionTypes } from './menu-permission.action';
import { MenuPermission } from 'app/core/models/menu-permission.model';


export const initialState: MenuPermission[] = null;
export function reducer(state = initialState, action: MenuPermissionActions): MenuPermission[] {
  switch (action.type) {
    case MenuPermissionActionTypes.SetMenuPermissions:
      state = action.payload;
      return state;
    case MenuPermissionActionTypes.RemoveMenuPermission:
      state = initialState;
      return state;
    default:
      return state;
  }
}
