import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedServiceService {
  private dataDetails: BehaviorSubject<boolean>;
  private exceptionManage: BehaviorSubject<boolean>;
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  private refreshing: BehaviorSubject<boolean>;

  constructor() {
    this.dataDetails = new BehaviorSubject<boolean>(false);
    this.exceptionManage = new BehaviorSubject<boolean>(false);
  }

  getValue(): Observable<boolean> {
    return this.dataDetails.asObservable();
  }

  setValue(newValue): void {
    this.dataDetails.next(newValue);
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */
  setException(value): void {
    this.exceptionManage.next(value);
  }

  /**
   * Getter
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getException(): Observable<boolean> {
    return this.exceptionManage.asObservable();
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  setRefresh(value): void {
    this.refreshing.next(value);
  }

  get refresh(): Observable<boolean> {
    return this.refreshing.asObservable();
  }
}
