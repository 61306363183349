import { ProfileActions, ProfileActionTypes } from './profile.action';
import { Profile } from '../../models/profile.model';

export const initialState: Profile = null;
export function reducer(state = initialState, action: ProfileActions): Profile {
  switch (action.type) {
    case ProfileActionTypes.SetProfile:
      state = action.payload;
      return state;
    case ProfileActionTypes.RemoveProfile:
      state = initialState;
      return state;
    default:
      return state;
  }
}
