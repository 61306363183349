import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-sos-modal',
  templateUrl: './sos-modal.component.html',
  styleUrls: ['./sos-modal.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class SosModalComponent implements OnInit {
  vehicle: any [] =[];



  constructor(
  
  public matDialogRef: MatDialogRef<SosModalComponent>,
  @Inject(MAT_DIALOG_DATA) private _data: any,
  private router: Router,
  )
  {
    this.vehicle.push(this._data.info);
  }

  ngOnInit(): void {
  }

  close(){
    this.matDialogRef.close()
  }

  route(item){
    const {JobOrderNo, TripNo} = item
    this.router.navigate([
      `/main/transaction-management/trip-tickets/trip-details-info/`, JobOrderNo, TripNo
    ]);

    this.close();
  }

}
