import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { select, Store } from "@ngrx/store";
import { RootState, selectUser } from "app/core/store";
import { SharedService } from "app/services/shared/shared-service.service";
import { environment } from "environments/environment";
import { filter, startWith, take } from "rxjs/operators";

@Component({
  selector: "blackbox",
  templateUrl: "./blackbox.component.html",
  styleUrls: ["./blackbox.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class BlackboxComponent implements OnInit {
  isShowChatHead: boolean = true;
  isShowChatBody: boolean = false;
  isShowChat: boolean = true;
  token: any;
  iframeUrl: any;
  companyId: string;
  serviceType: string;
  companyName: string;
  fullName: string;
  userId: string;
  userName: string;
  userImage: string;

  isDetails: boolean = false;

  currentUrl: string;
  previousUrl: string;

  constructor(
    private sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private store: Store<RootState>,
    private _router: Router
  ) {

    this.sharedBlackBox();
    this.sharedService.showBB.subscribe((data) => {
      this.isShowChatHead = data.isShowChatHead;
      this.isShowChatBody = data.isShowChatBody;
    });
  }

  sharedBlackBox() {
    this.sharedService.getBlackBoxUrl.subscribe((url) => {
      this._router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          startWith(this._router)
        )
        .subscribe((event: NavigationEnd) => {
          this.getUserDetails();
          let url1 = event.url;
          let typeListing = this.validateListingFilter(url1);
          const tokenFormat = `companyName=${this.companyName}&companyId=${this.companyId}&fullName=${this.fullName}&userId=${this.userId}&userName=${this.userName}&userImage=${this.userImage}&token=${this.token}&isBookingParty=false&listingType=${typeListing}`;

          if (
            url1.includes("trip-tickets/trip-details-info/") ||
            url1.includes("transaction-management/service-request")
          ) {
            const formattedUrl = `${url}&${tokenFormat}`;
            this.isDetails = true;
            this.closeIframe();
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              `${environment.blackbox}/?${formattedUrl}`
            );
            this.access(url);
            // this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.blackbox}/${tokenFormat}`)
          } else {
            this.getUserDetails();
            this.closeIframe();
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              `${environment.blackbox}/?${tokenFormat}&serviceType=Trucking`
            );
          }
        });
    });
  }

  validateListingFilter(url1) {
    if (url1.includes("transaction-management/trip-tickets")) {
      return "Trip Ticket Channel";
    }

    return "Service Level Channel,Service level Channel";
  }

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this._router)
      )
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.sharedService.setPreviousUrl(this.previousUrl);
      });

    let listingRoute;

    let _this = this;
    _this.sharedService.previousUrl$.subscribe((data: string) => {
      listingRoute = data;
    });
    window.addEventListener("message", function (e) {
      if (e.data?.message === "details") {
        const { serviceType, details, parentId, description, members } = e.data;
        _this.validateDetails(
          serviceType,
          details,
          parentId,
          description,
          members
        );
      }
      if (e.data?.message === "listing") {
        _this.getRouterUrl(listingRoute);
      }
    });
  }
  validateDetails(type, details, parentId, description, data) {
    if (description.includes("Trip")) {
      let trip = details.split("-");
      this.getRouterUrl(
        `/main/transaction-management/trip-tickets/trip-details-info/${trip[0]}/${trip[1]}`
      );
    }
    if (description.includes("Service")) {
      this.getServiceUrl(type, details, parentId, data);
    }

    if (description.includes("Reservation")) {
      this.getReservationUrl(type, details);
    }
  }

  getServiceUrl(type, details, parentId, member) {
    if (type === "Trucking") {
      let trucking = member[0].members.find(
        (res) => res.data.role === "Booking Party"
      );
      if (trucking) {
        let truckingAdmin = trucking.data.guid;
        let params = {
          reservationId: parentId,
          bookingPartyId: truckingAdmin,
        };
        this.getRouterUrl(
          `/main/transaction-management/service-request/service-ticket/${details}`,
          params
        );
      }
    } else if (type === "Trucking Linehaul") {
      this.getRouterUrl(
        `/main/transaction-management/booking-reservations/details/mile/${details}`
      );
    } else {
      this.getRouterUrl(
        `/main/transaction-management/booking-reservations/service-ticket/${parentId}`
      );
    }
  }

  getReservationUrl(type, details) {
    if (type === "Trucking") {
      this.getRouterUrl(
        `/main/transaction-management/booking-reservations/truckingdetails/${details}`
      );
    } else if (type === "Trucking Linehaul") {
      this.getRouterUrl(
        `/main/transaction-management/booking-reservations/details/mile/${details}`
      );
    } else {
      this.getRouterUrl(
        `/main/transaction-management/booking-reservations/details/${details}`
      );
    }
  }

  getRouterUrl(url, _params?) {
    this._router.navigateByUrl(url, { replaceUrl: true });
  }

  getUserDetails() {
    let token = JSON.parse(localStorage.getItem("token"));
    const {
      authenticationResult: { idToken },
    } = token && token.cognito;

    this.token = idToken;
    this.store
      .pipe(select(selectUser))
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const {
            company: { name, companyId },
            user: { name: userDetails, username, image, userId },
          } = result;
          this.companyId = companyId;
          this.companyName = name;
          this.fullName = userDetails;
          this.userName = username;
          this.userId = userId;
          this.userImage = image;
        }
      });
  }

  minimizeIframe() {
    this.isShowChat = !this.isShowChat;
  }

  closeIframe() {
    let data = {
      isShowChatBody: false,
      isShowChatHead: true,
    };
    this.sharedService.setshowBB(data);
  }

  iconClick() {
    let data = {
      isShowChatBody: true,
      isShowChatHead: false,
    };
    this.sharedBlackBox();
    this.sharedService.setshowBB(data);
    this.sharedService.setChatSupport(false);
  }

  access(reservationId?: string) {
    var iframe = document.getElementById("main_iframe") as HTMLIFrameElement;
  }
}
