import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseUtils } from '@fuse/utils';
import { Store, select } from '@ngrx/store';
import { TruckingService } from 'app/core/models/trucking-service.model';
import { RootState, selectUser } from 'app/core/store';
import { PopUpComponent } from 'app/layout/components/pop-up/pop-up.component';
import { DataService } from 'app/services/data.service';
import { MessagingService } from 'app/services/messaging.service';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { RejectTruckingComponent } from '../component/reject-trucking/reject-trucking.component';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CountdownTimerComponent implements OnInit {

  timeLeft: number = 15;
  data: any;
  interval;
  display: boolean;
  subscribeTimer: any;
  bookingDetails: any;
  tripRoutes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K']
  TruckingCompanyId: any;
  containers: any[] = []
  totalContainer: any = 0;
  routes: any = []
  serviceId: any;
  truckingId: any;
  pause: boolean = false;
  hdrGuid = [];
  serviceRoleId: any;
  serviceTicketId: any;
  constructor(
    public matDialogRef: MatDialogRef<CountdownTimerComponent>,
    public dialog: MatDialog,
    public dataService: DataService,
    public router: Router,
    private store: Store<RootState>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private messagingService: MessagingService,

  ) {
    this.data = _data;
    const { info, TruckingCompanyId } = _data
    this.TruckingCompanyId = TruckingCompanyId
    
    window.scrollTo(0, 0);
    this.dataService.getContainers(info.ServiceTicketId).subscribe(res => {
     
      res.data.forEach(container => {
        container.containerSummary.forEach(el => {
          this.totalContainer += el.totalContainer
        })
        container.lstRoutes.forEach(el => {
          this.routes.push(el)
        })
        container.containerSummary.map(res2 => {

          this.containers.push(res2)
        })
        const hdrGuid = container.requestHdrGuid
        this.hdrGuid.push(hdrGuid)
      })

      this.bookingDetails = res.data[0];
      this.serviceTicketId = this.bookingDetails.serviceTicketId
      this.registerDevice(this.hdrGuid)
    }, error => {
      this.openPop(
        {
          data: 'error',
          title: 'ERROR !',
          description: this.errorHandler(error)
        }
      );
    })


    this.store.pipe(select(selectUser)).pipe(take(1)).subscribe((result) => {
      this.serviceRoleId = result && result.company?.serviceId;
    })
  }

  viewRoutes(data) {
    return this.tripRoutes[data - 1]
  }

  ngOnInit(): void {
    this.oberserableTimer();
    this.showDialog();

  }
  openPop(data): void {
    this.dialog.open(PopUpComponent, {
      width: '20%',
      panelClass: 'contact-form-dialog',
      data: {
        data: data.data,
        title: data.title,
        description: data.description
      }
    });
  }

  errorHandler(data: any) {
    return FuseUtils.handleError(data);
  }
  oberserableTimer() {
    if (this.pause == false) {
      const source = timer(1000, 2000);
      const abc = source.subscribe(val => {
        this.subscribeTimer = this.timeLeft - val;
      });
    }
  }

  startTimer() {
    if (this.pause === false) {
      this.interval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.timeLeft = 15;
        }
      }, 1000)
    }
  }


  showDialog() {
    this.interval = 0;
    this.display = true;
    this.interval = setInterval(() => {
      this.timeLeft = --this.timeLeft <= 0 ? 15 : this.timeLeft;
      if (this.timeLeft === 15) {
        this.display = false;
        this.dialog.closeAll()
        this.close();
      }
    }, 1000);
  }

  close() {
    this.timeLeft = 15;
    clearInterval(this.interval);
  }

  viewServiceRequest() {
    this.dialog.closeAll()
    this._fuseSplashScreenService.show()
    setTimeout(() => {
      this.router.navigate(
        [
          `/main/transaction-management/service-request/`
        ],
        {
        }
      );
    }, 100);
    setTimeout(() => {
      this.router.navigate(
        [
          `/main/transaction-management/service-request/service-ticket`, this.bookingDetails.serviceTicketId
        ],
        {
          queryParams: {
            reservationId: this.bookingDetails?.reservationId,
            bookingPartyId: this.bookingDetails?.bookingPartyId
          }
        }
      );
      this._fuseSplashScreenService.hide()
    }, 500);

    // setTimeout(() => {
    //   location.reload()

    // }, 500)
  }


  acceptIncomming() {
   

    const payload =
    {
      bookingNumber: '',
      serviceRoleId: this.serviceRoleId,
      bookingConfirmationDocuments: [],
    }
    
    this._fuseSplashScreenService.show();
    this.dataService.confirmBooking( this.TruckingCompanyId,
      this.serviceTicketId, payload).subscribe(res => {
      this.openModal({
        data: "success",
        title: "SUCCESS !",
        description: 'Accepted Booking!',
        disableClose: true
      });
      this.matDialogRef.close()
      this._fuseSplashScreenService.hide();

    }, (error) => {
      this._fuseSplashScreenService.hide();
      console.warn(error);
      this.openModal({
        data: "error",
        title: "ERROR !",
        description: this.errorHandler(error),
        disableClose: true
      });
    })
  }

openRejectModal(){
    this.pause = true;
    clearInterval(this.interval);
    if (this.pause === true) {
      document.getElementById("circle").style.animationPlayState = "paused";
    }
    const modal = this.dialog.open(RejectTruckingComponent, {
      width: '50%',
      autoFocus: null,
      panelClass: 'accept-action-dialog',
      data: {
        type: "notification",
        serviceId: this.bookingDetails.serviceTicketId,
        truckingId: this.TruckingCompanyId
      },
    });
    modal.afterClosed().subscribe(res => {
      document.getElementById("circle").style.animationPlayState = "running";
      this.showDialog();
    })
  }

  openModal(data: any) {
    return this.dialog.open(PopUpComponent, {
      width: "20%",
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
        showDelete: data.showDelete,
        disableClose: data.disableClose
      },
    });
  }

  registerDevice(id) {
    id.forEach(element => {
      this.messagingService.RegisterDevice(element)
    });
  }

  /**
 * On destroy
 */
  ngOnDestroy(): void {
    this.hdrGuid.forEach(el => {
      this.messagingService.UnRegisterDevice(el)
    })
  }
}
