<div id="emergency-trip">
<div class="dialog-content-wrapper m-0 p-0">
  <mat-toolbar
    matDialogTitle
    class="logo mb-sm-0 red-500 pt-16"
    fxLayout="row"
    fxLayoutAlign="space-between start"
  >
    <span class="h3 font-weight-800 font-size-14"> Emergency </span>
    <button
      class="cursor-pointer"
      mat-icon-button
      (click)="close()"
      aria-label="Close dialog"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-toolbar>
  <div class="mat-dialog-conten">
    <div
      id="pop-up-wrapper"
      fusePerfectScrollbar
      [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
    >
      <div id="pop-up-page" style="text-align: left !important">
        <ng-container *ngIf="vehicle?.length">
            <ng-container *ngFor="let item of vehicle; let i = index">
        <div class="p-8">
          <div
            fxLayoutAlign="space-between"
            fxLayoutGap="10px"
            fxFlex="100"
            fxLayout="column"
          >
            <div class="pt-8 border-radius">
              <div fxLayout="row" fxLayoutAlign="space-between">
                <div
                  fxFlex="80"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <div fxFlex="40" class="px-8 pb-8">
                    <img class="border-radius" width="85" [src]="item?.VehicleImage | protectedImage | async" />
                  </div>
                  <div fxFlex="60" fxLayout="column">
                    <div class="text-bold"> {{item?.JobOrderNo}} / {{item?.TripNo}}</div>
                    <div class="red-fg">Vehicle Breakdown</div>
                    <div class="pt-8">{{item?.DriverName}} / {{item?.LicenseNumber}}</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" class="border-button p-12">
                  <button (click)="route(item)" mat-button class="red-500">Locate</button>
                </div>
              </div>
            </div>
          </div>
          </div>
            </ng-container>
        </ng-container>
<!-- 
            <div class="pt-8 border-radius">
              <div fxLayout="row" fxLayoutAlign="space-between">
                <div
                  fxFlex="80"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <div fxFlex="40" class="px-8 pb-8">
                    <img width="75" src="assets/images/logos/xlog.png" />
                  </div>
                  <div fxFlex="60" fxLayout="column">
                    <div class="text-bold">JO/0001 / TN-00001</div>
                    <div class="red-fg">Vehicle Breakdown</div>
                    <div class="pt-8">Man / NTQ00012</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" class="border-button p-12">
                  <button mat-button class="red-500">Locate</button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
