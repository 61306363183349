import { Action } from '@ngrx/store';
import { Company } from 'app/core/models/company.model';

export enum CompanyActionTypes {
  SetCompany = '[Company] Set Company',
  RemoveCompany = '[Company] Remove Company',
}

export class SetCompany implements Action {
  readonly type = CompanyActionTypes.SetCompany;
  constructor(public payload: Company) {}
}

export class RemoveCompany implements Action {
  readonly type = CompanyActionTypes.RemoveCompany;
  constructor() {}
}

export type CompanyActions = SetCompany | RemoveCompany;
