import { PortActions, PortActionTypes } from './port.action';
import { Port } from '../../models/port.model';

export const initialState: Port[] = null;
export function reducer(state = initialState, action: PortActions): Port[] {
  switch (action.type) {
    case PortActionTypes.SetPorts:
      state = action.payload;
      return state;
    case PortActionTypes.RemovePort:
      state = initialState;
      return state;
    default:
      return state;
  }
}
