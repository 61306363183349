import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { PopUpComponent } from "app/layout/components/pop-up/pop-up.component";
import { Store, select } from "@ngrx/store";
import { RootState, selectCompany } from "app/core/store";
import { take } from "rxjs/operators";
import { TransactionManagementService } from "app/services/transaction-management.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseUtils } from "@fuse/utils";
import { SnackbarComponent } from "@fuse/components/snackbar/snackbar.component";
import { SharedService } from "app/services/shared/shared-service.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-reject-trucking",
  templateUrl: "./reject-trucking.component.html",
  styleUrls: ["./reject-trucking.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class RejectTruckingComponent implements OnInit {
  rejectForm: FormGroup;
  truckingCompanyId: any;
  serviceId: any;
  data: any;
  type: any;
  constructor(
    public matDialogRef: MatDialogRef<RejectTruckingComponent>,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private store: Store<RootState>,
    private serviceManagement: TransactionManagementService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _refresher: SharedService,
    private _snackBar: MatSnackBar
  ) {
    this.store
      .pipe(select(selectCompany))
      .pipe(take(1))
      .subscribe((result) => {
        this.truckingCompanyId = result && result.companyId;
      });
    this.type = _data && _data.type;
    this.truckingCompanyId = _data.truckingId;
    this.serviceId = _data.serviceId;
    this.matDialogRef.disableClose = _data.disableClose;
  }

  ngOnInit(): void {
    this.getRejectFrom();
  }

  getRejectFrom() {
    this.rejectForm = this._formBuilder.group({
      reasonRejection: ["", [Validators.required]],
      remarksRejection: [""],
    });
  }

  close() {
    this.dialog.closeAll();
  }

  warning() {
    this.openModal({
      data: "warning",
      title: "WARNING !",
      description: "Sample Warning",
    });
  }

  submitReject() {
    if (this.rejectForm.valid) {
      const dialogRef = this.dialog.open(PopUpComponent, {
        width: "20%",
        panelClass: "contact-form-dialog",
        data: {
          data: "warning",
          title: "WARNING !",
          description: "Are you sure you want to reject this reservation?",
          showButtons: true,
          disableClose: true,
        },
      });
      ``;
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const payload = {
            rejectCancelReason: this.rejectForm.controls.reasonRejection.value,
            rejectCancelReasonDescription:
              this.rejectForm.controls.remarksRejection.value,
          };

          this.serviceManagement
            .rejectBooking(this.truckingCompanyId, this.serviceId, payload)
            .subscribe(
              (result) => {
                this._fuseSplashScreenService.hide();

                this._snackBar.openFromComponent(
                  SnackbarComponent,
                  FuseUtils.showSnackbarConfig(
                    "Success !",
                    "success",
                    `Rejection Successful`,
                    2000
                  )
                );
                this._refresher.setRefresh(true);
              },
              (error) => {
                this._fuseSplashScreenService.hide();
                this.openPop({
                  data: "error",
                  title: "ERROR !",
                  description: this.errorHandler(error),
                });
              }
            );
        }
      });
    }

    return;
  }
  openPop(data): void {
    this.dialog.open(PopUpComponent, {
      width: "20%",
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
      },
    });
  }

  errorHandler(data: any) {
    return FuseUtils.handleError(data);
  }
  cancelReject() {
    const dialogRef = this.dialog.open(PopUpComponent, {
      width: "20%",
      panelClass: "contact-form-dialog",
      data: {
        data: "information",
        title: "Info",
        description:
          "Are you sure you want to cancel? Changes will not be saved.",
        showButtons: true,
        disableClose: true,
      },
    });
    ``;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.type === "notification") {
          this.matDialogRef.close();
        } else {
          this.dialog.closeAll();
        }
      }
    });
  }
  openModal(data: any) {
    return this.dialog.open(PopUpComponent, {
      width: "20%",
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
        disableClose: data.disableClose,
      },
    });
  }
}
