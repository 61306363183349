import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
  {
    id: "applications",
    title: "Applications",
    type: "group",
    icon: "apps",
    children: [
      // {
      //   id: "dashboard",
      //   title: "Dashboard",
      //   translate: "",
      //   type: "item",
      //   icon: "xlog-dashboard",
      //   url: "/main/dashboard",
      // },
      {
        id: "profile",
        title: "Profile",
        translate: "",
        type: "collapsable",
        icon: "xlog-profile",
        children: [
          {
            id: "company-profile",
            title: "Company Profile",
            type: "item",
            url: "/main/profile/company-profile",
          },
          {
            id: "my-profile",
            title: "My Profile",
            type: "item",
            url: "/main/profile/my-profile",
          },
        ],
      },
      {
        id: "transaction-management",
        title: "Transaction Management",
        type: "collapsable",
        icon: "xlog-transaction-management",
        url: "/main/transactions",
        children: [
          {
            id: "service-request",
            title: "Service Request",
            type: "item",
            url: "/main/transaction-management/service-request",
          },
          {
            id: "trip-tickets",
            title: "Trip Tickets",
            type: "item",
            url: "/main/transaction-management/trip-tickets",
          },
        ],
      },
      {
        id: "container-management",
        title: "Container Management",
        type: "collapsable",
        icon: "xlog-container-management",
        children: [
          {
            id: "slot-booking",
            title: "Slot Booking",
            type: "item",
            url: "/main/container-management/slot-booking",
          },
        ],
      },
      {
        id: "fleet-management",
        title: "Fleet Management",
        translate: "",
        type: "collapsable",
        icon: "xlog-fleet-management",
        children: [
          {
            id: "vehicle",
            title: "Vehicle",
            type: "item",
            url: "/main/fleet-management/vehicles",
          },
          {
            id: "driver",
            title: "Driver",
            type: "item",
            url: "/main/fleet-management/drivers",
          },
          {
            id: "chasis",
            title: "Chassis",
            type: "item",
            url: "/main/fleet-management/chasis",
          },
          // {
          //     id: 'depot',
          //     title: 'Depot',
          //     type: 'item',
          //     url: '/main/fleet-management/depot'
          // },
        ],
      },

      {
        id: "service-rates",
        title: "Service & Rates",
        translate: "",
        type: "collapsable",
        icon: "xlog-service-rates",
        children: [
          {
            id: "trucking",
            title: "Trucking",
            type: "item",
            url: "/main/service-rates/services-trucking",
          },
        ],
      },

      {
        id: "finance-management",
        title: "Finance Management",
        translate: "",
        type: "collapsable",
        icon: "xlog-billing-management",
        children: [
          {
            id: "payable-receivable",
            title: "Payables & Receivables",
            type: "item",
            url: "/main/finance-management/payable-receivable",
          },
          // { NO COMPONENT YET
          //   id: 'billing',
          //   title: 'Billing',
          //   type: 'item',
          //   url: '/main/finance-management/billing',
          // },
          // {
          //   id: 'loans',
          //   title: 'Loans',
          //   type: 'item',
          //   url: '/main/finance-management/loans',
          // },
        ],
      },

      {
        id: "access-management",
        title: "Access Management",
        translate: "",
        type: "collapsable",
        icon: "xlog-access-management",
        children: [
          {
            id: "user",
            title: "User",
            type: "item",
            url: "/main/access-management/user",
          },
          {
            id: "group",
            title: "Group",
            type: "item",
            url: "/main/access-management/group",
          },
        ],
      },
      {
        id: "accreditation",
        title: "Accreditation",
        translate: "",
        type: "collapsable",
        icon: "xlog-accreditation",
        children: [
          {
            id: "accredited",
            title: "Accredited",
            type: "collapsable",
            children: [
              {
                id: "customer",
                title: "Customer",
                type: "item",
                url: "main/accreditation/accredited/customer",
              },
            ],
          },
          {
            id: "accredited-by",
            title: "Accredited By",
            type: "collapsable",
            children: [
              {
                id: "shippingLine",
                title: "Shipping Line",
                type: "item",
                url: "main/accreditation/accredited-by/shipping-line",
              },
            ],
          },
        ],
      },

      {
        id: "settings",
        title: "Settings",
        translate: "",
        type: "collapsable",
        icon: "xlog-settings",
        children: [
          {
            id: "contacts",
            title: "Contacts and Preferred provider",
            type: "item",
            url: "/main/settings/contacts",
          },
          // {
          //   id: "document-template",
          //   title: "Document Template",
          //   type: "item",
          //   url: "/main/settings/document", //no component yet
          // },
          // {
          //   id: "email-template",
          //   title: "Email Template",
          //   type: "item",
          //   url: "/main/settings/email", //no component yet
          // },
        ],
      },
      {
        id: "other-facilities",
        title: "Other Facilities",
        translate: "",
        type: "collapsable",
        icon: "xlog-billing-management",
        children: [
          {
            id: "facilities-loan",
            title: "Loan",
            type: "item",
            url: "/main/other-facilities/loan",
          },
          {
            id: "facilities-insurance",
            title: "Insurance",
            type: "item",
            url: "/main/other-facilities/insurance",
          },
        ],
      },
    ],
  },
];

export const navigationKYC: FuseNavigation[] = [
  {
    id: "applications",
    title: "Applications",
    type: "group",
    icon: "apps",
    children: [
      // {
      //   id: "dashboard",
      //   title: "Dashboard",
      //   translate: "",
      //   type: "item",
      //   icon: "xlog-dashboard",
      //   url: "/main/dashboard",
      // },
      {
        id: "profile",
        title: "Profile",
        translate: "",
        type: "collapsable",
        icon: "xlog-profile",
        children: [
          {
            id: "company-profile",
            title: "Company Profile",
            type: "item",
            url: "/main/profile/company-profile",
          },
          {
            id: "my-profile",
            title: "My Profile",
            type: "item",
            url: "/main/profile/my-profile",
          },
        ],
      },
    ],
  },
];
