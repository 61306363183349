import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { PopUpComponent } from 'app/layout/components/pop-up/pop-up.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { InfoNotifComponent } from './info-notif/info-notif.component';


@NgModule({
  declarations: [
    PopUpComponent,
    InfoNotifComponent
],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,

    FuseSharedModule

  ],
  exports: [
    PopUpComponent,
    InfoNotifComponent
]
})
export class PopUpModule { }
