import { Action } from '@ngrx/store';
import { MenuPermission } from 'app/core/models/menu-permission.model';

export enum MenuPermissionActionTypes {
  SetMenuPermissions = '[MenuPermission] Set MenuPermissions',
  RemoveMenuPermission = '[MenuPermission] Remove MenuPermissions',
}

export class SetMenuPermissions implements Action {
  readonly type = MenuPermissionActionTypes.SetMenuPermissions;
  constructor(public payload: MenuPermission[]) {}
}

export class RemoveMenuPermission implements Action {
  readonly type = MenuPermissionActionTypes.RemoveMenuPermission;
  constructor() {}
}

export type MenuPermissionActions = SetMenuPermissions | RemoveMenuPermission;
