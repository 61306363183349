export const environment = {
  globalAggregator: "https://global-aggregator-api.develop.xlog.asia",
  globalCMS: "https://global-cms-api.develop.xlog.asia",
  clientManagement: "https://client-management-api.develop.xlog.asia",
  authManagementAuthentication:
    "https://auth-service-api.develop.xlog.asia/auth",

  seaFreightService: "https://sea-freight-api.develop.xlog.asia",

  truckingService: "https://trucking-service-api.develop.xlog.asia",

  transactionManagement: "https://transaction-service-api.develop.xlog.asia",

  ssoUrl: "https://sso-middleware-api.develop.xlog.asia",
  loginUrl: "https://login.develop.xlog.asia/",

  trackAndTraceURL: "https://track-and-trace-api.develop.xlog.asia",
  trackTraceApiKey: "x6GDwyfee674qbBRMVu3e4Jfgpi6pVQm2375FcaF",

  accreditationUrl: "https://accreditation-api.develop.xlog.asia",
  generateInvoiceImageApi:
    "https://fromimage-api.develop.xlog.asia/develop-getBase64FromImageUrl",
  base64Key: "Ys8T7uJ82h74Sfvm2HH8vaBjmOgiJBL1amH7RHfP",

  firebase: {
    apiKey: "AIzaSyDpKrQ73GqsM7HAgGTqBUbmBU0q8nR8Vss",
    authDomain: "optimus-realtime.firebaseapp.com",
    databaseURL: "https://optimus-realtime.firebaseio.com",
    projectId: "optimus-realtime",
    storageBucket: "optimus-realtime.appspot.com",
    messagingSenderId: "713358032967",
    appId: "1:713358032967:web:38c2104466d42e1ccaf162",
  },

  production: true,
  hmr: false,

  developerMap: true,
  googleKey: "AIzaSyDnC23xX9YyLwYIbdx4nkegfRH6LTIrcP0",
  xlogChat: "https://smalltalkui.chatframe.calicubilly.com",
  // blackbox: 'https://bbx-clone.vercel.app'
  blackbox: "https://web.dev.blackboxapp.net",
  actorLink: "https://optimus-xlog-trucking.dev.xlog.asia",
  cyWebUrl: "https://develop-cy-web.lesoleil.xlog.asia",
};
