<div class="multiple-fileviewer m-0 p-0" inner-scroll>
  <mat-toolbar class="accent-500 border-radius-bottom-8">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title font-size-16"> Supporting Document </span>
      <div fxLayout="row" fxLayoutGap="8px">
        <div
          fxLayout="column"
          fxLayoutAlign="center"
          (click)="dialogRef.close()"
          class="cursor-pointer"
          aria-label="Close dialog"
        >
          <mat-icon>cancel</mat-icon>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-24 w-100-p border" fxLayout="row" fxLayoutAlign="center center">
    <ng-container *ngIf="attachments.length === 1; else moreData">
      <div>
        <ul class="glide glide__slides">
          <li
            class="p-12"
            style="width: auto"
            *ngFor="let f of attachments; let i = index"
          >
            <!-- (click)="selectedService(payment)" -->
            <div class="item" (click)="selectAttachment(f)">
              <img [src]="f.fileType === 'image' ? f.filePath : f.fileIcon" />
              <div class="drop-name font-size-12">{{ f.fileName }}</div>
            </div>
          </li>
        </ul>
      </div>
    </ng-container>
    <ng-template #moreData>
      <div class="glide border">
        <div class="glide__arrows" data-glide-el="controls">
          <button
            class="glide__arrow glide__arrow--left accent"
            data-glide-dir="<"
          >
            <mat-icon class="i-size">arrow_left</mat-icon>
          </button>
          <button
            class="glide__arrow glide__arrow--right accent"
            data-glide-dir=">"
          >
            <mat-icon class="i-size">arrow_right</mat-icon>
          </button>
        </div>
        <div data-glide-el="track" class="glide__track" style="padding: 0 50px">
          <ul class="glide__slides">
            <li
              class="p-12 cursor-pointer"
              style="width: auto"
              *ngFor="let f of attachments; let i = index"
            >
              <div class="item" (click)="selectAttachment(f)">
                <img [src]="f.fileType === 'image' ? f.filePath : f.fileIcon" />
                <div class="drop-name font-size-12">{{ f.fileName }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="p-24" [ngStyle]="{ display: showViewer ? 'block' : 'none' }">
    <div class="border">
      <div #iframeContainers id="iframeContainers"></div>
    </div>
  </div>
</div>
