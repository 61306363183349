import { Action } from '@ngrx/store';
import { Exception } from 'app/core/models/exception.model';

export enum ExceptionActionTypes {
  SetException = '[Exception] Set Exception',
  RemoveException = '[Exception] Remove Exception',
}

export class SetException implements Action {
  readonly type = ExceptionActionTypes.SetException;
  constructor(public payload: Exception) {}
}

export class RemoveException implements Action {
  readonly type = ExceptionActionTypes.RemoveException;
  constructor() {}
}

export type ExceptionActions = SetException | RemoveException;
