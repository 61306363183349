<!-- HEADERS -->
<div *ngIf="data.showSuccessHeader" class="s-12">
  <div
    fxLayout="column"
    fxLayoutAlign="end end"
    class="cursor-pointer successHeader"
    (click)="matDialogRef.close('close')"
    aria-label="Close dialog"
  >
    <mat-icon>cancel</mat-icon>
  </div>
</div>
<div *ngIf="data.showErrorHeader" class="s-12">
  <div
    fxLayout="column"
    fxLayoutAlign="end end"
    class="cursor-pointer errorHeader"
    (click)="matDialogRef.close('close')"
    aria-label="Close dialog"
  >
    <mat-icon>cancel</mat-icon>
  </div>
</div>
<div *ngIf="data.showWarningHeader" class="s-12">
  <div
    fxLayout="column"
    fxLayoutAlign="end end"
    class="cursor-pointer warningHeader"
    (click)="matDialogRef.close('close')"
    aria-label="Close dialog"
  >
    <mat-icon>cancel</mat-icon>
  </div>
</div>

<div class="inner-scroll p-24" fxLayout="row" fxLayoutAlign="center center">
  <div
    id="pop-up-wrapper"
    fusePerfectScrollbar
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
  >
    <div id="pop-up-page">
      <!-- NOTIF LOGOS -->
      <div *ngIf="data.data === 'success'" class="s-100" style="color: #47c399">
        <img
          src="/assets/icons/booking-reservation/check-alert.svg"
          class="mb-8"
          alt=""
        />
        <div class="title border-bottom pb-24">
          {{ data.title }}
        </div>
      </div>
      <div *ngIf="data.data === 'error'" class="s-100" style="color: red">
        <img
          src="/assets/icons/booking-reservation/error-alert.svg"
          class="mb-8"
          alt=""
        />
        <div class="title border-bottom pb-24">
          {{ data.title }}
        </div>
      </div>
      <div *ngIf="data.data === 'warning'" class="s-100" style="color: orange">
        <img
          src="/assets/icons/booking-reservation/warning-alert.svg"
          class="mb-8"
          alt=""
        />
        <div
          class="title border-bottom pb-24"
          *ngIf="!data.showException"
          [ngStyle]="{ margin: data.showSwitch ? '32px auto 0' : '32px auto' }"
        >
          {{ data.title }}
        </div>
        <div
          *ngIf="data.showException"
          class="title border-bottom pb-24"
          style="color: black"
        >
          {{ data.title }}
        </div>
      </div>

      <div
        *ngIf="data.showSwitch"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <p class="font-size-20 font-weight-700">Schedule Conflict</p>
        <div fxLayout="row">
          <div fxLayout="column" fxLayoutalign="start start">
            <div class="infoTitle font-weight-600 font-size-17">
              {{ data?.showSwitchDetails?.jobOrderNoTripNo }}
            </div>
          </div>
        </div>
        <div fxLayout="row">
          <div fxLayout="column" fxLayoutalign="start start">
            <div class="font-size-16 my-4">
              {{ data?.showSwitchDetails?.firstName }}
              {{ data?.showSwitchDetails?.lastName }}
            </div>
          </div>
        </div>
        <div fxLayout="row">
          <div fxLayout="column" fxLayoutalign="start start">
            <div class="font-size-14">
              {{ data?.showSwitchDetails?.scheduleFrom }}
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="data.unassign"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <h2>Unassignment Successful.</h2>
        <br />
        <div class="font-size-16" fxLayout="column">
          <div class="accent-500-fg">
            {{ data.showSwitchDetails.driver }}
          </div>
          <div class="font-size-12">
            {{ data.showSwitchDetails.mobile }}
          </div>
        </div>
      </div>

      <div *ngIf="data.assign" fxLayout="column" fxLayoutAlign="center center">
        <h2>Assigment successful</h2>
        <div class="font-size-16" fxLayout="column">
          <div class="accent-500-fg">
            {{ data.assignData.name }}
          </div>
          <div class="font-size-12">
            {{ data.assignData.number }}
          </div>
        </div>
      </div>

      <div
        *ngIf="data.showSwitchSuccess"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <h2>Reassignment successful</h2>
        <div class="font-size-16" fxLayout="column">
          <div class="accent-500-fg">
            {{ data.showSwitchSuccessData.fromName }}
          </div>
          <div class="font-size-12">
            {{ data.showSwitchSuccessData.fromNumber }}
          </div>
        </div>
        <br />
        <div class="font-size-16" fxLayout="column">
          <div class="accent-500-fg">
            {{ data.showSwitchSuccessData.ToName }}
          </div>
          <div class="font-size-12">
            {{ data.showSwitchSuccessData.ToNumber }}
          </div>
        </div>
      </div>

      <div *ngIf="data.success" fxLayout="column" fxLayoutAlign="center center">
        <h2>Reassignment successful</h2>
      </div>

      <div
        *ngIf="data.preAssigned"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <h2>Pre-assigned driver detected.</h2>
        <div class="font-size-16" fxLayout="column">
          <div class="infoTitle">
            {{ data.preAssignedData.fromName }}
          </div>
        </div>
        <br />
        <div class="font-size-16" fxLayout="column">
          <div>Reassign driver too ?</div>
        </div>
      </div>

      <div
        *ngIf="data.preAssignedVehicle"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <h2>Pre-assigned vehicle detected.</h2>
        <div class="font-size-16" fxLayout="column">
          <div class="infoTitle">
            {{ data.preAssignedVehicleData.fromName }}
          </div>
          <div>
            {{ data.preAssignedVehicleData.plateNumber }}
          </div>
        </div>
        <br />
        <div class="font-size-16" fxLayout="column">
          <div>Reassign vehicle too ?</div>
        </div>
      </div>

      <div
        *ngIf="data.showSwitchVehicleSuccess"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <h2>Reassignment successful</h2>
        <div class="accent-500-fg">
          {{ data.showSwitchSuccessData.fromjobOrderNoTripNo }}
        </div>
        <div class="font-size-16" fxLayout="column">
          <div class="font-size-12">
            {{ data.showSwitchSuccessData.frombrand }} /{{
              data.showSwitchSuccessData.frommodel
            }}
          </div>
          <div class="font-size-12">
            {{ data.showSwitchSuccessData.fromplatenumber }}
          </div>
        </div>
        <br />
        <div class="accent-500-fg">
          {{ data.showSwitchSuccessData.jobOrderNoTripNo }}
        </div>
        <div class="font-size-16" fxLayout="column">
          <div class="font-size-12">
            {{ data.showSwitchSuccessData.brand }} /{{
              data.showSwitchSuccessData.model
            }}
          </div>
          <div class="font-size-12">
            {{ data.showSwitchSuccessData.platenumber }}
          </div>
        </div>
      </div>

      <!-- DESCRIPTION -->
      <div class="description">
        <div [innerHTML]="data.description"></div>
      </div>

      <!-- BUTTONS -->
      <button
        mat-flat-button
        class="submit-button"
        color="accent"
        *ngIf="
          !data.showButtons &&
          !data.showDelete &&
          !data.showException &&
          !data.showProceed &&
          !data.showSwitch &&
          !data.showSwitchSuccess &&
          !data.preAssigned &&
          !data.preAssignedVehicle &&
          !data.addItem
        "
        (click)="matDialogRef.close('close')"
      >
        CLOSE
      </button>
      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.addItem"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close()"
        >
          No
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="proceed()"
        >
          Proceed
        </button>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showButtons"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close()"
        >
          No
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="save()"
        >
          Yes
        </button>
      </div>
      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showProceed"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close()"
        >
          <ng-container *ngIf="data?.showProceedNo; else Close"
            >No</ng-container
          >
          <ng-template #Close>Close</ng-template>
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="save()"
        >
          Proceed
        </button>
      </div>
      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showDelete"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close([''])"
        >
          No
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="matDialogRef.close(['delete'])"
        >
          Yes
        </button>
      </div>
      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showException"
      >
        <button
          mat-flat-button
          class="grey-400-bg fuse-white-fg mr-8"
          (click)="matDialogRef.close('close')"
        >
          Close
        </button>
        <button
          mat-flat-button
          class="warningButton"
          (click)="matDialogRef.close(['manage'])"
        >
          Manage
        </button>
      </div>
      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showSwitch"
      >
        <button
          mat-flat-button
          class="grey-400-bg fuse-white-fg mr-8"
          (click)="matDialogRef.close('close')"
        >
          Close
        </button>
        <button
          mat-flat-button
          class="accent"
          (click)="matDialogRef.close(['switch'])"
        >
          <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
          </svg>
          Switch
        </button>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showSwitchSuccess"
      >
        <button
          mat-flat-button
          class="accent"
          (click)="matDialogRef.close(['ok'])"
        >
          <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
          </svg>
          OK
        </button>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.preAssigned || data.preAssignedVehicle"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close()"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          class="nobutton mr-8"
          color="accent"
          (click)="matDialogRef.close(['no'])"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g
              id="Group_1176"
              data-name="Group 1176"
              transform="translate(-0.403)"
            >
              <g id="Group_534" data-name="Group 534">
                <rect
                  id="Rectangle_303"
                  data-name="Rectangle 303"
                  width="24"
                  height="24"
                  rx="12"
                  transform="translate(0.403)"
                  fill="#cc375f"
                />
              </g>
              <g
                id="Group_629"
                data-name="Group 629"
                transform="translate(8.082 7.834)"
              >
                <g id="Group_642" data-name="Group 642">
                  <g id="Group_1063" data-name="Group 1063">
                    <g
                      id="Group_1061"
                      data-name="Group 1061"
                      transform="translate(0.131)"
                    >
                      <path
                        id="Path_673"
                        data-name="Path 673"
                        d="M982.389,691.963a6.1,6.1,0,0,0-.933-.1,2.8,2.8,0,0,0-.842.054,3.686,3.686,0,0,0-.858.494,14.257,14.257,0,0,0-1.544,1.323c-.632.6-1.2,1.2-1.7,1.775-.442.513-.9,1.082-1.371,1.693l0-.008a1.868,1.868,0,0,0-.13.141l-.011.015a3.031,3.031,0,0,0-.872,2.309l.033.062c.072.042.142.088.21.135l.259.094c.088.007.176.016.265.021a4.078,4.078,0,0,0,1.322-.4c.072-.065.14-.134.214-.2l.016-.023a24.325,24.325,0,0,1,2.791-3.436,15.823,15.823,0,0,1,3.047-2.561,2.3,2.3,0,0,0,.443-.312.658.658,0,0,0,.172-.335.612.612,0,0,0-.087-.47A.626.626,0,0,0,982.389,691.963Z"
                        transform="translate(-974.084 -691.859)"
                        fill="#fff"
                      />
                    </g>
                    <g
                      id="Group_1062"
                      data-name="Group 1062"
                      transform="translate(0 0.426)"
                    >
                      <path
                        id="Path_674"
                        data-name="Path 674"
                        d="M980.361,700.261a13.572,13.572,0,0,0-1.259-1.469c-.575-.6-1.143-1.145-1.689-1.615-.464-.4-.978-.816-1.527-1.239a.229.229,0,0,1-.094-.058,4.248,4.248,0,0,0-1.579-.942.254.254,0,0,1-.052-.028,1.388,1.388,0,0,0-.738,0,.6.6,0,0,0-.086.053c-.046.121-.09.243-.133.365-.006.034-.014.068-.023.1-.005.067-.012.135-.016.2a3.929,3.929,0,0,0,.315,1.118,1.984,1.984,0,0,0,.216.318l.061.041a23.175,23.175,0,0,1,3.27,2.657,15.072,15.072,0,0,1,2.438,2.9,2.207,2.207,0,0,0,.3.422.631.631,0,0,0,.319.163.583.583,0,0,0,.447-.083.6.6,0,0,0,.261-.4,5.806,5.806,0,0,0,.094-.888,2.668,2.668,0,0,0-.051-.8A3.515,3.515,0,0,0,980.361,700.261Z"
                        transform="translate(-973.164 -694.86)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>

          No
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="matDialogRef.close(['yes'])"
        >
          <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
          </svg>
          Yes
        </button>
      </div>
    </div>
  </div>
</div>
