<div id="reject-trucking">
  <div>
    <div
      mat-button
      class="border-radius-4"
      fxLayout="column"
    >
      <div class="accent py-8 px-12" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px" (click)="close()">
        <span>Reject</span>
        <mat-icon>
          arrow_drop_up
        </mat-icon>
      </div>
    </div>
    <form class="reject-dropdown" [formGroup]="rejectForm">
      <div class="p-16 pb-0 overflow-y-auto" fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex>
        <mat-form-field
          appearance="outline"
          floatLabel="always"
          class="w-100-p h-100-p"
        >
          <mat-label>Select Reason for Rejection</mat-label>
          <mat-select formControlName="reasonRejection">
            <mat-option value="Other">Others</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          floatLabel="always"
          class="w-100-p h-100-p"
        >
          <mat-label>Reason for Rejection</mat-label>
          <textarea matInput rows="11" formControlName="remarksRejection"></textarea>
        </mat-form-field>
      </div>
    </form>
    <div fxLayout="row" fxLayoutAlign="flex-end" class="pr-16 pt-12 pb-24" fxLayoutGap="10px">
      <button (click)="cancelReject()" mat-flat-button class="mat-elevation-z3 grey-600">
        <mat-icon>clear</mat-icon>Cancel
      </button>
      <button mat-flat-button class="mat-elevation-z3 accent blue-500"
        (click)="submitReject()">
        <mat-icon>
          keyboard_arrow_right
        </mat-icon>
        Reject
      </button>
    </div>
  </div>
</div>