import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { NgxDropzoneModule } from "ngx-dropzone";

import { MultipleFileviewerComponent } from './multiple-fileviewer/multiple-fileviewer.component';
import { BlackboxComponent } from './blackbox/blackbox.component';
import { ChatSupportComponent } from './chat-support/chat-support.component';
import { RouteCompletionComponent } from './route-completion/route-completion.component';
import { ProtectedImagePipe } from "app/core/pipe/protected-image/protected-image.pipe";

@NgModule({
  declarations: [MultipleFileviewerComponent, BlackboxComponent, ChatSupportComponent, RouteCompletionComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatMenuModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatDividerModule,
    NgxDropzoneModule,
    MatRadioModule,
  ],
  exports: [
    BlackboxComponent,
    ChatSupportComponent
  ],
  providers: [
    ProtectedImagePipe
  ]
})
export class ReusableModule { }
