import { ExceptionActions, ExceptionActionTypes } from './exception.action';
import { Exception } from 'app/core/models/exception.model';


export const initialState: Exception = null;
export function reducer(state = initialState, action: ExceptionActions): Exception {
  switch (action.type) {
    case ExceptionActionTypes.SetException:
      state = action.payload;
      return state;
    case ExceptionActionTypes.RemoveException:
      state = initialState;
      return state;
    default:
      return state;
  }
}
