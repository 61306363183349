
import { TruckingService } from 'app/core/models/trucking-service.model';
import { TruckingServiceActions, TruckingServiceActionTypes } from './trucking-service.action';


export const initialState: TruckingService = null;

export function reducer(state = initialState, action:TruckingServiceActions): TruckingService {
    switch(action.type) {
        case TruckingServiceActionTypes.SetTruckingServices:
            state = action.payload;
            return state;
        case TruckingServiceActionTypes.RemoveTruckingService:
            state = initialState;
            return state;
        default:
            return state;
    }
}
