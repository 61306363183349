import { Action } from '@ngrx/store';
import { TripTicket } from 'app/core/models/trip-tickets.model';

export enum TripTicketActionTypes {
  SetTripTickets = '[TripTicket] Set TripTickets',
  RemoveTripTicket = '[TripTicket] Remove TripTickets',
}

export class SetTripTickets implements Action {
  readonly type = TripTicketActionTypes.SetTripTickets;
  constructor(public payload: TripTicket) {}
}

export class RemoveTripTicket implements Action {
  readonly type = TripTicketActionTypes.RemoveTripTicket;
  constructor() {}
}

export type TripTicketActions = SetTripTickets | RemoveTripTicket;
