import "moment-timezone";
import * as moment from "moment";

export class FuseUtils {
  /**
   * Filter array by string
   *
   * @param mainArr
   * @param searchText
   * @returns {any}
   */
  public static filterArrayByString(mainArr, searchText): any {
    if (searchText === "") {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter((itemObj) => {
      return this.searchInObj(itemObj, searchText);
    });
  }
  /**
   * Get ARAP Event
   *
   * @static
   * @param {number} eventId
   * @return {*}  {string}
   * @memberof FuseUtils
   */
  public static getARAPEvent(eventId: number): string {
    if (eventId !== -1) {
      const statuses = [
        "All",
        "New Invoice",
        "Pending Acceptance",
        "Invoice - Pending Revision",
        "Invoice - for Payment",
        "Invoice - Payment Unsuccessful",
        "Invoice - paid",
        "Invoice - For Payment Approval",
        "Invoice - Processing Payment",
        "Invoice - Approved",
        "Invoice - For Payment Validation",
      ];
      return statuses[eventId];
    }
    return "N / A";
  }

  public static getARAPStatus(eventId: number): boolean {
    if (eventId === 1 || eventId === 3) {
      return true;
    }
    return false;
  }
  public static getCurrentEventTrucking(eventId: number): string {
    if (eventId !== -1) {
      const statuses = [
        "New", // Shipper 0
        "Cancelled By Booking Party", // Shipper 1
        "Cancelled By Service Provider", // Trucker 2
        "Rejected", // Trucker 3
        "Confirmed Booking", // Trucker 4
        "Invoice Released", // Trucker 5
        "Invoice Revision", // Shipper 6
        "Process Payment", // Shipper 7
        "Payment Submitted", // Shipper 8
        "Payment Validation", // Trucker 9
        "Completed", // Trucker 10
        "Payment Successful", // Trucker 11
        "Payment Unsuccessful", // Trucker 12
        "Invoice For Payment Approval", // Trucker 13
        "For Payment", // Trucker 14
        "Approved Payment", // Shipper/Consignee 15
      ];
      return statuses[eventId];
    }
    return "N/A";
  }

  /**
   * Search in object
   *
   * @param itemObj
   * @param searchText
   * @returns {boolean}
   */
  public static searchInObj(itemObj, searchText): boolean {
    for (const prop in itemObj) {
      if (!itemObj.hasOwnProperty(prop)) {
        continue;
      }

      const value = itemObj[prop];

      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  /**
   * Search in array
   *
   * @param arr
   * @param searchText
   * @returns {boolean}
   */
  public static searchInArray(arr, searchText): boolean {
    for (const value of arr) {
      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  /**
   * Search in string
   *
   * @param value
   * @param searchText
   * @returns {any}
   */
  public static searchInString(value, searchText): any {
    return value.toLowerCase().includes(searchText);
  }

  /**
   * Generate a unique GUID
   *
   * @returns {string}
   */
  public static generateGUID(): string {
    function S4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  }

  /**
   * Toggle in array
   *
   * @param item
   * @param array
   */
  public static toggleInArray(item, array): void {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  /**
   * Handleize
   *
   * @param text
   * @returns {string}
   */
  public static handleize(text): string {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  /**
   * Convert Date with no time zone
   *
   * @static
   * @param {string} date
   * @param {string} [format='MMM DD, YYYY hh:mm:ss a']
   * @return {*}  {string}
   * @memberof FuseUtils
   */
  public static convertDateNoTimeZone(
    date: string,
    format: string = "MMM DD, YYYY hh:mm:ss a"
  ): string {
    if (moment(date).isValid()) {
      return moment.utc(date).format(format);
    }
    return "--";
  }

  /**
   * Converts date to local time (No Object)
   *
   * @static
   * @param {string} date
   * @param {string} [format='MMM DD, YYYY']
   * @return {*}  {string}
   * @memberof FuseUtils
   */
  public static plainConvertDateToLocal(
    date: string,
    format: string = "MMM DD, YYYY hh:mm:ss a"
  ): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const d = new Date();
    const n = d.getTimezoneOffset();

    if (moment(date).isValid()) {
      return moment.utc(date).tz(timeZone).format(format);
    }
    return "--";
  }

  /**
   * Get Current Event
   *
   * @static
   * @param {number} eventId
   * @return {*}  {string}
   * @memberof FuseUtils
   */
  public static getCurrentEvent(eventId: number): string {
    if (eventId !== -1) {
      const statuses = [
        "New", // Shipper 0
        "Cancelled By Booking Party", // Shipper 1
        "Cancelled By Service Provider", // Trucker 2
        "Rejected", // Trucker 3
        "Confirmed Booking", // Trucker 4
        "Invoice Released", // Trucker 5
        "Invoice Revision", // Shipper 6
        "Process Payment", // Shipper 7
        "Payment Submitted", // Shipper 8
        "Payment Validation", // Trucker 9
        "Completed", // Trucker 10
        "Payment Successful", // Trucker 11
        "Payment Unsuccessful", // Trucker 12
        "Invoice For Payment Approval", // Trucker 13
        "For Payment", // Trucker 14
        "Approved Payment", // Shipper/Consignee 15
      ];
      return statuses[eventId];
    }
    return "N / A";
  }

  public static truckingTransactionStatus(): any[] {
    return [
      "New", // Shipper 0
      "Cancelled By Booking Party", // Shipper 1
      "Cancelled By Service Provider", // Trucker 2
      "Rejected", // Trucker 3
      "Confirmed Booking", // Trucker 4
      "Invoice Released", // Trucker 5
      "Invoice Revision", // Shipper 6
      "Process Payment", // Shipper 7
      "Payment Submitted", // Shipper 8
      "Payment Validation", // Trucker 9
      "Completed", // Trucker 10
      "Payment Successful", // Trucker 11
      "Payment Unsuccessful", // Trucker 12
      "Invoice For Payment Approval", // Trucker 13
      "For Payment", // Trucker 14
      "Approved Payment", // Shipper/Consignee 15
    ];
  }

  /**
   * Returns List of Sea Freight Event Status
   *
   * @static
   * @return {*}  {any[]}
   * @memberof FuseUtils
   */
  public static seaFreightStatus(): any[] {
    return [
      "New", // Shipper 0
      "CancelledByBookingParty", // Shipper 1
      "CancelledByServiceProvider", // Agent 2
      "Rejected", // Agent 3
      "ConfirmedBooking", // Agent 4
      "ContainerReleased", // Agent 5
      "ShippingInstructionSubmitted", // Shipper 6
      "ShippingInstructionRevised", // Shipper 7
      "ShippingInstructionConfirmed", // Agent 8
      "ShippingInstructionRejected", // Agent 9
      "BlDraftSubmitted", // Agent 10
      "BlDraftConfirmed", // Shipper 11
      "BlDraftRejected", // Shipper 12
      "ProcessPaymentOrigin", // Shipper 13
      "LoadingConfirmation", // Agent 14
      "FinalBlReleased", // Agent 15
      "ArrivalNoticeReleased", // Agent 16
      "DestinationInvoiceReleased", // Agent 17
      "ProcessPaymentDestination", // Consignee 18
      "DeliveryOrderReleased", // Agent 19
      "ReleaseEmptyContainer", // Agent 20
      "OtherPaymentCharges", // Agent 21
      "Completed", // Agent 22
      "ReturnEmptyContainer", // Agent 23
      "OriginInvoiceReleased", // Agent 24
      "PaymentSubmittedOrigin", // Shipper 25
      "PaymentSubmittedDestination", // Consignee 26
      "PaymentValidationOrigin", // Agent 27
      "PaymentValidationDestination", // Agent 28
      "OtherChargesInvoiceReleaseOrigin", // Shipper 29
      "OtherChargesPaymentProcessingOrigin", // Shipper 30
      "OtherChargesPaymentSubmittedOrigin", // Shipper 31
      "OtherChargesPaymentValidationOrigin", // Agent 32
      "OtherChargesInvoiceReleaseDestination", // Agent 33
      "OtherChargesPaymentProcessingDestination", // Consignee 34
      "OtherChargesPaymentSubmittedDestination", // Consignee 35
      "OtherChargesPaymentValidationDestination", // Agent 36
      "PartialLoadingConfirmation", // Agent 37
      "PartialReleaseEmptyContainer", // Agent 38
      "PartialReturnEmptyContainer", // Agent 39
      "OriginInvoiceRevision", // Shipper 40
      "DestinationInvoiceRevision", // Consignee 41
      "OriginOtherChargesInvoiceRevision", // Shipper 42
      "DestinationOtherChargesInvoiceRevision", // Consignee 43
    ];
  }
  /**
   * Returns List of Sea Freight Event Status
   *
   * @static
   * @return {*}  {any[]}
   * @memberof FuseUtils
   */
  public static truckingStatus(): any[] {
    return ["New", "In Progress", "Complete", "Cancelled", "Rejected"];
  }

  /**
   * Parses File to an Object
   *
   * @param {string} attachment
   * @memberof ViewBlDraftComponent
   */
  public static fileParser(attachment: string) {
    if (attachment === "") {
      return {
        filePath: "--",
        fileName: "--",
        fileIcon: "--",
        fileType: "--",
      };
    }

    const temp = attachment.split(".com/");

    let fileWithGuid: string = temp[1];

    let file = decodeURI(
      fileWithGuid.substr(36, fileWithGuid.length).replace(/\?.*/, "")
    );

    let fileBreakDown = file.split(".");

    let fileIcon = "";

    let type = fileBreakDown[1].toLocaleLowerCase();

    if (type === "pdf") {
      fileIcon = "assets/icons/document/PDF.png";
    } else if (type === "doc") {
      fileIcon = "assets/icons/document/Docx.png";
    } else if (type === "xlsx") {
      fileIcon = "assets/icons/document/Excel.png";
    } else if (type === "png" || type === "jpg" || type === "jpeg") {
      fileIcon = attachment;
    } else {
      fileIcon =
        "https://firebasestorage.googleapis.com/v0/b/optimus-prime-6b029.appspot.com/o/icon%2Fdocument%20folder.svg?alt=media&token=6708d610-ee8e-4961-88f1-1fa4c4e40a26";
    }

    return {
      filePath: attachment,
      fileName: file,
      fileIcon: fileIcon,
      fileType: type,
    };
  }

  /**
   * Currency Formatter
   *
   * @param {*} data
   * @return {*}
   * @memberof FuseUtils
   */
  public static currencyFormat(data: number) {
    if (data !== null && typeof data !== "undefined") {
      let value = data.toString();
      let decimal = "00";
      let wholeNumber = "0";
      let thousands = "0";
      let found = value.includes(".");

      if (found) {
        // data with decimal
        decimal = value.substring(value.length - 2);
        if (decimal.includes(".")) {
          //if true, then decimal is single digit
          // if false, then decimal is double digit
          let singleDigit = decimal.replace(".", "");
          decimal = singleDigit + "0";
        }

        wholeNumber = value.slice(0, -2);
        if (wholeNumber.includes(".")) {
          //if true, then decimal is double digit
          // if false, then decimal is single digit
          wholeNumber = wholeNumber.replace(".", "");
        }
      } else {
        // data without decimal
        wholeNumber = value;
      }

      //Adds comma separator
      thousands = wholeNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // Join to a single string
      let combined = [thousands, decimal];
      const final = combined.join(".");
      return final;
    } else {
      return "0.00";
    }
  }

  /**
   *
   *
   * @static
   * @param {*} data
   * @return {*}
   * @memberof FuseUtils
   */
  public static handleError(data) {
    if (data?.errors?.length > 0) {
      return data.errors[0]?.message ? data.errors[0]?.message : data.title;
    }
    if (data?.title) {
      return data.title;
    }
    if (data?.message) {
      return data.message;
    }
    if (data?.messages) {
      return data?.messages.toString();
    }
    if (data?.type == "error") {
      return "Something went Wrong!";
    }
    return data ? data : "Something went Wrong!";
  }

  public static FormatQuery(filter) {
    const newObj = {};
    Object.entries(filter).forEach(([k, v]) => {
      if (v === Object(v)) {
        newObj[k] = this.FormatQuery(v);
      } else if (v) {
        newObj[k] = filter[k];
      }
    });
    return newObj;
  }

  public static showSnackbarConfig(
    title: string,
    type: string,
    description: string,
    duration: number = 3000
  ) {
    let data: any = {
      panelClass: [`${type}-snackbar`],
      verticalPosition: "top",
      horizontalPosition: "end",
      data: {
        title: title,
        description: description,
        icon: `xlog-${type}`,
      },
      duration,
    };
    return data;
  }
}
