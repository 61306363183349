import { TripTicket } from 'app/core/models/trip-tickets.model';
import { TripTicketActions, TripTicketActionTypes } from './trip-tickets.action';


export const initialState: TripTicket = null;

export function reducer(state = initialState, action:TripTicketActions): TripTicket {
    switch(action.type) {
        case TripTicketActionTypes.SetTripTickets:
            state = action.payload;
            return state;
        case TripTicketActionTypes.RemoveTripTicket:
            state = initialState;
            return state;
        default:
            return state;
    }
}
