import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Store } from '@ngrx/store';
import { RootState } from '../core/store';
import { Observable } from 'rxjs';
// import { SetCompany } from './core/store/company/company.action';
// import { SetUser } from './core/store/user/user.action';
// import { User } from './core/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(    private http: HttpClient,
                  private store: Store<RootState>) {
                    
                   }


  changePassword(body): Observable<any> {
    const token = JSON.parse(localStorage.getItem('token'));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'AccessToken': token && token.cognito && token.cognito.authenticationResult && token.cognito.authenticationResult.accessToken,
        'Authorization': token && token.cognito && token.cognito.authenticationResult && token.cognito.authenticationResult.idToken
      })
    };
    return this.http.patch<any>(`${environment.authManagementAuthentication}/api/v1/auth/change-password`, body, httpOptions);
  }



  portListing(filter,search): Observable<any> {
    let params = new HttpParams().set('query',`${filter}:${search}`);
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/port/filter`, {params: params});
  }


  getContainers(data): Observable<any> {

    return this.http.get<any>(`${environment.truckingService}/oat/api/v1/ModalDetails/ServiceDetailsBooking?ServiceTicketId=${data}`);
  }

  confirmBooking(truckingCompanyId, serviceId, payload): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/${truckingCompanyId}/${serviceId}/confirm-booking`, payload);
  }
  rejectBooking(payload): Observable<any>{
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/${payload.truckingCompanyid}/${payload.serviceTicketId}/reject-booking`,{})
  }








}
