import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";

@Component({
  selector: "app-pop-up",
  templateUrl: "./pop-up.component.html",
  styleUrls: ["./pop-up.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class PopUpComponent implements OnInit {
  data: any;
  constructor(
    public matDialogRef: MatDialogRef<PopUpComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.data = _data;
    // matDialogRef.disableClose = _data.disableClose;
    matDialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  save() {
    this.matDialogRef.close("update");
  }

  proceed() {
    this.matDialogRef.close(this.data.action);
  }
}
