import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Store } from '@ngrx/store';
import { RootState } from './core/store';
import { SetCompany } from './core/store/company/company.action';
import { SetUser } from './core/store/user/user.action';
import { User } from './core/models/user.model';
import { Observable } from 'rxjs';
import { AccessControlService } from './services/access-control.service';
import { SetMenuPermissions } from './core/store/menu-permission/menu-permission.action';
import { FuseUtils } from '@fuse/utils';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient,
    private store: Store<RootState>,
    private accessControlService: AccessControlService
  ) { }

  /**
   * Get Current Logged in User
   *
   * @returns {Promise} promise
   * @memberof ProfileService
   */
  getUser() {
    return new Promise<any>((resolve, reject) => {
      let token = JSON.parse(localStorage.getItem('token'));
      let companyServiceId = token && token.service && token.service.companyServiceId;
      this.http.get<any>(`${environment.clientManagement}/clients/api/v1/profile/service/${companyServiceId}`).subscribe(res => {
        const user: User = res.data.profile;
        this.store.dispatch(new SetUser(user));

        const {
          profile: {
            user: {
              username
            }
          }
        } = res.data

        this.accessControlService.getMenuPermissions(username)
          .subscribe((result) => {
            if (result) {
              this.store.dispatch(new SetMenuPermissions(result && result.data))
              resolve('');
            }
          })
      }, err => {
        reject(err);
      });
    })
  }

  /**
   * Get Current Logged in Company
   *
   * @returns {Promise} promise
   * @memberof ProfileService
   */
  initGetCompanyProfile() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/details`)
        .subscribe(res => {
          const dat = res && res.data && res.data.company
          if (dat) {
            this.store.dispatch(new SetCompany(res.data.company));
            resolve('');
          }
        }, err => {
          reject(err);
        })
    })
  }

  /**
   * Fetch the Company Profile / Details
   *
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  getCompanyProfile(): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/details`,)
  }


  /**
   * Fetch the User Profile / Details
   *
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  getMyProfile(): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/user/profile`)
  }

  /**
   * Updates Company Profile
   *
   * @param {*} payload
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  updateCompanyProfile(payload): Observable<any> {
    return this.http.put<any>(`${environment.clientManagement}/clients/api/v1/company`, payload)
  }

  /**
   * Updates User Profile
   *
   * @param {*} payload
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  updateMyProfile(payload): Observable<any> {
    return this.http.put<any>(`${environment.clientManagement}/clients/api/v1/user`, payload)
  }

  /**
   * Get all audit logs in Company Profile
   *
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  getCompanyProfileAuditLogs(): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/profile/logs`)
  }

  /**
   * Gets the information of Audit Log
   *
   * @param {string} id
   * @returns {Observable<any>}
   * @memberof ProfileService
   */
  viewCompanyProfileAuditLog(id: string): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/logs/details/${id}`)
  }

  /**
   * Get all audit logs in My Profile
   *
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  getMyProfileAuditLogs(payload): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v2/users/profile/logs`, { params: FuseUtils.FormatQuery(payload) })
  }

  getUserAuditLogs(userId, payload): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v2/users/${userId}/logs`, { params: FuseUtils.FormatQuery(payload) })
  }

  downloadCompanyProfileAuditLogs(): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/profile/logs/download`, {
      responseType: 'blob' as 'json'
    })
  }

  // downloadMyProfileAuditLogs(payload): Observable<any> {
  //   return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/user/profile/logs/download`, {
  //     responseType: 'blob' as 'json'
  //   })
  // } 

  downloadMyProfileAuditLogs(payload): Observable<any> {
    let headers = new HttpHeaders({
      Accept: 'text/csv',
    });
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v2/users/profile/logs/export`, {
      params: FuseUtils.FormatQuery(payload),
      headers,
      responseType: 'blob' as 'json'
    })
  }


  getAdditionalInformation(): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/kyc/company-sections`)
  }

  submitCompanyIndividual(payload, type): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/company-beneficial-owners/${type}`,
      payload
    );
  }

  companyDirectors(payload, action): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/company-directors/${action}`,
      payload
    );
  }

  getDocumentType(): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/kyc/document-types`)
  }

  getTotalEmployee(): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/kyc/total-number-of-employees`)
  }

  submitCompanyStructure(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/company-structure/submit`,
      payload
    );
  }

  draftCompanyStructure(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/company-structure/draft`,
      payload
    );
  }

}
