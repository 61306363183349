import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private scoreCard: BehaviorSubject<boolean>;
  private scheduleData: BehaviorSubject<boolean>;
  private blackboxUrl: BehaviorSubject<any>;
  private chatsupport: BehaviorSubject<any>;
  private isShowBB: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  private refreshing: BehaviorSubject<boolean>;

  constructor() {
    this.scoreCard = new BehaviorSubject<boolean>(false);
    this.scheduleData = new BehaviorSubject<boolean>(false);
    this.blackboxUrl = new BehaviorSubject(false);
    this.chatsupport = new BehaviorSubject(false);
    this.refreshing = new BehaviorSubject<boolean>(false);
  }

  /**
   * Getter
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getScoreCard(): Observable<boolean> {
    return this.scoreCard.asObservable();
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */
  setScoreCard(value): void {
    this.scoreCard.next(value);
  }

  get getBlackBoxUrl(): Observable<any> {
    return this.blackboxUrl.asObservable();
  }

  setBlackBoxUrl(value) {
    this.blackboxUrl.next(value);
  }

  get chatSupport(): Observable<any> {
    return this.chatsupport.asObservable();
  }

  setChatSupport(value) {
    this.chatsupport.next(value);
  }

  get showBB(): Observable<any> {
    return this.isShowBB.asObservable();
  }

  setshowBB(value) {
    this.isShowBB.next(value);
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  setRefresh(value): void {
    this.refreshing.next(value);
  }

  get refresh(): Observable<boolean> {
    return this.refreshing.asObservable();
  }
}
