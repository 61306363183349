import { BookingActions, BookingActionTypes } from './booking.action';
import { Booking } from '../../models/booking.model';

// export interface State {
//   Bookings: Booking;
// }

export const initialState: Booking = null;
export function reducer(state = initialState, action: BookingActions): Booking {
  switch (action.type) {
    case BookingActionTypes.SetBooking:
      state = action.payload;
      return state;
    case BookingActionTypes.RemoveBooking:
      state = initialState;
      return state;
    default:
      return state;
  }
}
