import { Action } from '@ngrx/store';
import { TruckingService } from 'app/core/models/trucking-service.model';

export enum TruckingServiceActionTypes {
  SetTruckingServices = '[TruckingService] Set TruckingServices',
  RemoveTruckingService = '[TruckingService] Remove TruckingServices',
}

export class SetTruckingServices implements Action {
  readonly type = TruckingServiceActionTypes.SetTruckingServices;
  constructor(public payload: TruckingService) {}
}

export class RemoveTruckingService implements Action {
  readonly type = TruckingServiceActionTypes.RemoveTruckingService;
  constructor() {}
}

export type TruckingServiceActions = SetTruckingServices | RemoveTruckingService;
