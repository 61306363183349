<div id="with-trip">
<div class="emergency page-layout carded fullwidth">
    <div mat-dialog-title>
        <mat-toolbar class="logo mb-sm-0 red-500 pt-16" fxLayout="row" fxLayoutAlign="space-between start">
            <span class="h3 font-weight-800 font-size-14">
                Emergency / SOS
            </span>
            <!-- <mat-icon (click)="matDialogRef.close()">cancel</mat-icon> -->
            <button class="cursor-pointer" mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-toolbar>
    </div>
    <div mat-dialog-content class="mat-dialog-content">
        <div fxLayoutAlign="center">
            <h1 class="red-fg">Vehicle Breakdown</h1>
        </div>
        <ng-container *ngIf="vehicle?.length">
            <ng-container *ngFor="let item of vehicle; let i = index">
        <div fxLayoutAlign="start" class="content-modal p-24" fxLayout="column">
            <div class="border-radius mb-4">
                <div class="p-4">
                    <div fxFlex="30" class="p-8">
                        <img class="border-radius" width="85" [src]="item?.VehicleImage"   >
                    </div>
                    <div fxFlex="70" fxLayout="column">
                        <span class="text-bold pb-8">Vehicle</span>
                        <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center">
                            <div class="info-line" fxLayout="row">
                                <div class="title" fxFlex="50">Brand :</div>
                                <div class="info" fxFlex="50">{{item?.Brand}}</div>
                            </div>
                            <div class="info-line " fxLayout="row">
                                <div class="title" fxFlex="50">Model :</div>
                                <div class="info" fxFlex="50">{{item?.Model}}</div>
                            </div>
                            <div class="info-line " fxLayout="row">
                                <div class="title" fxFlex="50">Plate Number :</div>
                                <div class="info" fxFlex="50">{{item?.PlateNumber}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-radius mb-4">
                <div class="p-4">
                    <div fxFlex="30" class="p-8">
                        <img class="border-radius" width="85" [src]="item?.DriverImage">
                    </div>
                    <div fxFlex="70" fxLayout="column">
                        <span class="text-bold pb-8">Driver</span>
                        <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center">
                            <div class="info-line" fxLayout="row">
                                <div class="title" fxFlex="50">Name :</div>
                                <div class="info" fxFlex="50">{{item?.DriverName}}</div>
                            </div>
                            <div class="info-line " fxLayout="row">
                                <div class="title" fxFlex="50">License Number:</div>
                                <div class="info" fxFlex="50">{{item?.LicenseNumber}}</div>
                            </div>
                            <div class="info-line " fxLayout="row">
                                <div class="title" fxFlex="50">Mobile Number :</div>
                                <div class="info" fxFlex="50">{{item?.MobileNumber}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div fxLayoutAlign="center" class="text-bold">
            Remarks
        </div>
        <div class="p-8">
            <div fxLayoutAlign="start" class="border p-24">
                {{item?.Remarks}}
            </div>
        </div>
        </ng-container>
        </ng-container>
    </div>
</div>
</div>