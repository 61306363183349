<div class="inner-scroll p-24" fxLayout="row" fxLayoutAlign="center center">
  <div
    id="notification-modal"
    fusePerfectScrollbar
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
  >
    <div id="pop-up-page">
      <div class="s-100 text-uppercase accent-fg">
        <img src="/assets/images/svg/Infopopup.svg" class="mb-8" alt="" />
        <div class="title border-bottom pb-24">
          {{ data.title }}
        </div>
      </div>
      <div class="description">
        <div [innerHTML]="data.description"></div>
      </div>

      <div class="mt-8" fxLayout="row" fxLayoutAlign="center space-around">
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close()"
          *ngIf="data?.closeButton"
        >
          Close
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="action(data)"
        >
          {{ data.button?.title }}
        </button>
      </div>
    </div>
  </div>
</div>
