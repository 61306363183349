import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessagingService } from 'app/services/messaging.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-route-completion',
  templateUrl: './route-completion.component.html',
  styleUrls: ['./route-completion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RouteCompletionComponent implements OnInit {
  timeLeft: number = 15;
  interval;
  pause: boolean = false;
  data: any;
  display: boolean;
  subscribeTimer: any;
  details: any;
  constructor(
    public matDialogRef: MatDialogRef<RouteCompletionComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private messagingService: MessagingService,
  ) {
    this.data = _data;
    const { info, TruckingCompanyId } = _data
    this.details = info;
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.oberserableTimer();
    this.showDialog();
  }

  oberserableTimer() {
    if (this.pause == false) {
      const source = timer(1000, 2000);
      const abc = source.subscribe(val => {
        this.subscribeTimer = this.timeLeft - val;
      });
    }
  }

  startTimer() {
    if (this.pause === false) {
      this.interval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.timeLeft = 15;
        }
      }, 1000)
    }
  }

  showDialog() {
    this.interval = 0;
    this.display = true;
    this.interval = setInterval(() => {
      this.timeLeft = --this.timeLeft <= 0 ? 15 : this.timeLeft;
      if (this.timeLeft === 15) {
        this.display = false;
        this.dialog.closeAll()
        this.close();
      }
    }, 1000);
  }

  close() {
    this.timeLeft = 15;
    clearInterval(this.interval);
  }

}
