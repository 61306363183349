import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { Response } from 'app/core/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class TransactionManagementService {

  constructor(
    private http: HttpClient
  ) { }

  getServiceDetails(truckingCompanyId: string, serviceTicketId: any, bookingPartyId: any, reservationId: any): Observable<any> {
    let params = new HttpParams()
      .set('ServiceTicketId', serviceTicketId)
      .set('ReservationId', reservationId)
      .set('BookingPartyId', bookingPartyId)

    return this.http.get<any>(`${environment.truckingService}/oat/api/v1/GetServiceDetails?TruckingCompanyId=${truckingCompanyId}`,
      { params });
  }

  /**
   * Get Listing of Service Requests
   *
   * @param {string} shippingLineId
   * @param {*} payload
   * @return {*}  {Observable<*>}
   * @memberof ServiceTicketService
   */
  getServiceList(truckingCompanyId: string, payload: any): Observable<any> {
    let params = new HttpParams()
      .set('query', payload.query)
      .set('pageNumber', payload.page + 1)
      .set('pageSize', payload.pageSize)
      .set('orderBy', payload.orderBy)

    return this.http.get<any>(`${environment.transactionManagement}/otm/api/v1/transactions/trucking/trucking-company/${truckingCompanyId}`,
      { params: params });
  }

  /**
   * Download File
   *
   * @param {*} payload
   * @param {string} type
   * @return {*}  {Observable<any>}
   * @memberof ServiceTicketService
   */
  downloadFile(payload: any, type: string): Observable<any> {
    let params = new HttpParams()
      .set('truckingCompanyId', payload.truckingCompanyId)
      .set('orderBy', payload.orderBy)
      .set('query', payload.query)
      .set('pageNumber', payload.page + 1)
      .set('pageSize', payload.pageSize)

    let headers = new HttpHeaders({
      Accept: type === 'csv' ? 'text/csv' : '',
    });

    let responseType = `${type === 'csv' ? 'text' : 'blob'}` as 'json';

    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/trucking/file/trucking-company/${type}`,
      { params: params, headers, responseType: responseType }
    );
  }

  reassignDriver(tripNo, driverId): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/${tripNo}/driver-assign/${driverId}`, {});
  }

  reassignVehicle(tripId, vehicleId): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/${tripId}/vehicle-assign/${vehicleId}`, {});
  }

  acceptBooking(truckingCompanyId, serviceId, payload): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/${truckingCompanyId}/${serviceId}/confirm-booking`, payload);
  }

  rejectBooking(truckingCompanyId, serviceId, payload): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/${truckingCompanyId}/${serviceId}/reject-booking`, payload);
  }
  truckingBookingCancellation(payload, booking, serviceTicketId): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/booking-party/${booking}/${serviceTicketId}/cancel-booking`,
      payload
    );
  }

  validatePayment(truckingCompanyId, serviceId, payload): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/${truckingCompanyId}/${serviceId}/validate-payment`, payload);
  }

  manageInvoice(truckingCompanyId, serviceId, payload): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/${truckingCompanyId}/${serviceId}/release-invoice`, payload);
  }

  getTransactionLogs(ServiceTicketId, TruckingCompanyId, BookingPartyId): Observable<any> {
    return this.http.get<any>(`${environment.truckingService}/oat/api/v1/TransactionLogs?ServiceTicketId=${ServiceTicketId}&TruckingCompanyId=${TruckingCompanyId}&BookingPartyId=${BookingPartyId}`)
  }

  completeTransaction(truckingCompanyId, serviceId): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/${truckingCompanyId}/${serviceId}/complete-booking`, {});
  }

  getDocumentAttachmentTrip(truckingCompanyId: string, payload: any): Observable<any> {
    let params = new HttpParams()
      .set('TripNo', payload.tripNo)
      .set('JobOrderNo', truckingCompanyId)
    return this.http.get<any>(`${environment.truckingService}/oat/api/v1/trip/get-documents`,
      { params: params });
  }


  getTripHistory(truckingCompanyId: string, payload: any): Observable<any> {
    let params = new HttpParams()
      .set('TripNo', payload.tripNo)
      .set('JobOrderNo', truckingCompanyId)

    return this.http.get<any>(`${environment.truckingService}/oat/api/v1/GetTripHistory`,
      { params: params });
  }

  vehicleAssign(payload): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/${payload.tripId}/vehicle-assign/${payload.vehicleId}`, payload)
  }

  checkConflictvehicleAssign(payload): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/v1/CheckVehicleScheduleConflict?DriverId=${payload.driverId}`, payload)
  }


  getAvailableVehicle(payload): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/v1/GetAvailableVehicle`, payload)
  }


  getAvailableDriver(payload): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/v1/GetAvailableDriver`, payload)
  }

  getVehicleHistory(jobOrder, TripNo): Observable<any> {
    let params = new HttpParams()
      .set('TripNo', TripNo)
      .set('JobOrderNo', jobOrder)

    return this.http.get<any>(`${environment.truckingService}/oat/api/v1/trip/vehicle-history`,
      { params: params })
  }

  getDriverHistory(jobOrder, TripNo): Observable<any> {
    let params = new HttpParams()
      .set('TripNo', TripNo)
      .set('JobOrderNo', jobOrder)

    return this.http.get<any>(`${environment.truckingService}/oat/api/v1/trip/driver-history`,
      { params: params })
  }

  getSOSList(serviceTicket): Observable<any> {
    return this.http.get<any>(`${environment.truckingService}/oat/api/driver-app/GetSOSListPerServiceTicket?ServiceTicketId=${serviceTicket}`)
  }

  getScoreCard(id): Observable<any> {
    return this.http.get<any>(`${environment.transactionManagement}/otm/api/v1/transactions/dashboard-score-card?column=truckingCompanyId&id=${id}`)
  }


  getJobOrderData(jobOrder): Observable<any> {
    return this.http.get<any>(`${environment.truckingService}/oat/api/v1/GetTruckingContainers?JobOrderNo=${jobOrder}`)
  }

  updateContainerNumber(payload): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/v1/UpdateTruckingContainers`, payload)
  }

  switchDriver(tripId, driverId, payload): Observable<any> {
    const pass = null;
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/${tripId}/driver-assign/${driverId}?tripId=${payload.tripId}&driverId=${payload.driverId}&isSwitchDriver=${payload.isSwitchDriver}&SwitchToAcquiredTruckingId=${payload.SwitchToAcquiredTruckingId}&IsPreAssignedVehicle=${payload.IsPreAssignedVehicle}&PreAssignedVehicleId=${payload.PreAssignedVehicleId}`, pass)
  }

  checkDriverConfilct(driverId, payload): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/v1/CheckVehicleScheduleConflict?VehicleId=${driverId}`, payload)
  }

  getDocumentCategory(): Observable<any> {
    return this.http.get<any>(`${environment.truckingService}/oat/api/driver-app/GetDocumentCategories`);
  }

  postTripDocument(payload): any {
    return this.http.post<any>(`${environment.truckingService}/oat/api/trucking-admin/uploaded-trip-documents`, payload);
  }

  completeTransactionMile(truckingCompanyId, serviceId): Observable<any> {
    return this.http.post<any>(`${environment.truckingService}/oat/api/v1/miles/${truckingCompanyId}/${serviceId}/complete-booking`, {});
  }

  downloadExcelFormat(id) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/download-template?truckingCompanyId=${id}`,
      {
        responseType: "blob" as "json",
      }
    );
  }

  uploadBulkBooking(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/upload`,
      payload
    );
  }

  createBulkBooking(data): Observable<any> {
    return this.http.post<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/trucking/bulk-booking`,
      data
    );
  }
}

