import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FuseUtils } from "@fuse/utils";
import Glide, {
  Controls,
  Breakpoints,
} from "@glidejs/glide/dist/glide.modular.esm";
import { ProtectedImagePipe } from "app/core/pipe/protected-image/protected-image.pipe";

@Component({
  selector: 'app-multiple-fileviewer',
  templateUrl: './multiple-fileviewer.component.html',
  styleUrls: ['./multiple-fileviewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultipleFileviewerComponent implements OnInit {
  attachments: any[];
  images: any[];

  showViewer: boolean = false

  constructor(
    public dialogRef: MatDialogRef<MultipleFileviewerComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private protectedImage: ProtectedImagePipe
  ) {
    this.images = _data.file
    this.getAttachmentFile();
  }

  getAttachmentFile(): any {
    this.attachments = this.images.map((attachment) => {
      return this.fileParser(attachment);
    });
    setTimeout(() => {
      if (this.attachments.length > 1) {
        this.setGlide(this.attachments.length);
      }
    }, 500);
  }

  fileParser(attachment: string) {
    return FuseUtils.fileParser(attachment);
  }

  setGlide(data): void {
    if (data == 2) {
      new Glide(".glide", {
        type: "carousel",
        startAt: 0,
        perView: data,
      }).mount({ Controls, Breakpoints });
    } else {
      new Glide(".glide", {
        type: "carousel",
        startAt: 0,
        perView: 3,
      }).mount({ Controls, Breakpoints });
    }
  }

  selectAttachment(data) {
    this.showViewer = true
    if (data.filePath) {
      const checkIframe = document.querySelector("#iframe-pdfs");
      if (checkIframe) {
        checkIframe.remove();
      }
      const targetElement = document.querySelector("#iframeContainers");
      let iframe;
      this.protectedImage.transform(data.filePath ? data.filePath : data).then(presignedDocument => {
        iframe = document.createElement('iframe');
        iframe.style.cssText = 'width:100%; height: 400px;';
        iframe.id = 'iframe-pdfs';
        iframe.src = presignedDocument;
        targetElement.appendChild(iframe);
      })
    }
  }

  ngOnInit(): void {

  }

}
