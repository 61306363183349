import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { select, Store } from '@ngrx/store';
import { RootState, selectUser } from 'app/core/store';
import { SharedService } from 'app/services/shared/shared-service.service';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'chat-support',
  templateUrl: './chat-support.component.html',
  styleUrls: ['./chat-support.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})

export class ChatSupportComponent implements OnInit {

  showChat: boolean = false;
  iframeUrl: any;
  userName: string;
  company: string;
  name: string;
  constructor(
    private _sharedService: SharedService,
    private store: Store<RootState>,
    private sanitizer: DomSanitizer,
  ) {

    this._sharedService.chatSupport.subscribe((data) => {
      this.getUserData();
      this.showChat = data;

      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.xlogChat}/?username=${this.userName}&company=${this.company}&name=${this.name}`)
    })

  }

  ngOnInit(): void {
    // data
  }

  getUserData() {
    this.store.pipe(select(selectUser)).pipe(take(1)).subscribe((result) => {
      if (result) {
        const {
          company: {
            name,
          },
          user: {
            name: userDetails,
            username,
          }
        } = result
        this.userName = username;
        this.company = name;
        this.name = userDetails
      }
    })
  }

  access() {
    document.getElementById("main_iframe");
  }

  closeIframe() {
    this._sharedService.setChatSupport(false)
  }

  iconClick() {
    let data = {
      isShowChatBody: false,
      isShowChatHead: true
    }
    this._sharedService.setshowBB(data);
    this._sharedService.setChatSupport(true)

  }
}
