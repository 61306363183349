import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { navigation } from "app/navigation/navigation";
import { Store, select } from "@ngrx/store";
import { RootState, selectUser } from "app/core/store";
import { TokenService } from "app/token.service";
import { SetCompany } from "app/core/store/company/company.action";
import { SetProfile } from "app/core/store/profile/profile.action";
import { SetUser } from "app/core/store/user/user.action";
import { environment } from "environments/environment";
import { SetPorts } from "app/core/store/port/port.action";
import { BookingService } from "app/services/booking/booking.service";
import { MessagingService } from "app/services/messaging.service";
import { SetException } from "app/core/store/exception/exception.action";
import { SharedService } from "app/services/shared/shared-service.service";
import { Router } from "@angular/router";

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  company: any;
  serviceTypes: any[];

  showSupport: boolean = false;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private store: Store<RootState>,
    private tokenService: TokenService,
    private bookingService: BookingService,
    private messagingService: MessagingService,
    private _shareService: SharedService,
    private router: Router
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: "Online",
        icon: "icon-checkbox-marked-circle",
        color: "#4CAF50",
      },
      {
        title: "Away",
        icon: "icon-clock",
        color: "#FFC107",
      },
      {
        title: "Do not Disturb",
        icon: "icon-minus-circle",
        color: "#F44336",
      },
      {
        title: "Invisible",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#BDBDBD",
      },
      {
        title: "Offline",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#616161",
      },
    ];

    this.languages = [
      {
        id: "en",
        title: "English",
        flag: "us",
      },
      {
        id: "tr",
        title: "Turkish",
        flag: "tr",
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.store
      .pipe(select(selectUser))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res) {
          this.company = res.company;

          this.bookingService
            .getServiceTypes(res.company.serviceId)
            .subscribe((res) => {
              this.serviceTypes = res.data;
            });
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === "top";
        this.rightNavbar = settings.layout.navbar.position === "right";
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  logout() {
    this.tokenService.setToken({});
    this.store.dispatch(new SetCompany(null));
    this.store.dispatch(new SetUser(null));
    this.store.dispatch(new SetProfile(null));
    this.store.dispatch(new SetPorts(null));
    this.store.dispatch(new SetException(null));
    localStorage.removeItem("refreshToken");

    // For Booking
    this.messagingService
      .UnRegisterDevice2(this.company.companyId)
      .then((res) => {
        localStorage.removeItem("token");
        location.replace(environment.loginUrl);
      });
  }

  chatSupport() {
    this.showSupport = !this.showSupport;
    this._shareService.setChatSupport(this.showSupport);
  }

  clickSlotBooking() {
    this.router.navigate([`/main/slot-booking`], { replaceUrl: true });
  }
}
