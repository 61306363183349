<div fxLayout="column" class="dialog-content-wrapper m-0 p-0">
  <div fxLayout="row" fxLayoutAlign="start">
    <mat-toolbar matDialogTitle class="accent ">
      <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
        <div id="countdown" >
          <div id="countdown-number">{{ timeLeft | number:'2.0' }}</div>
          <svg width="160" height="160">
            <circle id="circle" r="18" cx="20" cy="20" ></circle>
          </svg>
        </div>
          <span class="dialog-title">{{ data.title }} </span>
          <div class="cursor-pointer" (click)="matDialogRef.close()" aria-label="Close dialog">
            <mat-icon>cancel</mat-icon>
          </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
  <div class="content">
    <div id="pop-up-wrapper" fusePerfectScrollbar [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }">
      <div fxLayoutAlign="start" class="p-24" fxLayout="row">
        <div fxFlex="50" fxAlign="row">
          <div class="info-line">
            <div class="info">Booking Reservation Number</div>
            <div class="info font-weight-600">{{bookingDetails?.serviceTicketId}}</div>
          </div>
          <div class="info-line">
            <div class="info">Total Container</div>
            <div class="info font-weight-600">{{totalContainer || '-'}} Containers</div>
          </div>
        </div>
        <div fxFlex="50">
          <div class="info-line">
            <div class="info">Company Logistics</div>
            <div class="info font-weight-600">{{bookingDetails?.bookingPartyName}}</div>
          </div>
        </div>
      </div>
        <hr class="hr-text" />
      <div class="p-12 pt-0" fxLayout="column">
        <div class="text-center accent-fg font-weight-800">Container Summary</div>

        <div class="p-12" fxLayout="row">
          <table class="simple">
            <thead class="light-blue">
              <tr>
                <th>Container Type</th>
                <th>Container Size</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr class="font-weight-600" *ngFor="let container of containers">
                <td>{{container?.containerTypeName}}</td>
                <td>{{container?.containerSizeName}}</td>
                <td>{{container?.totalContainer}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="p-24 pb-8" fxLayout="row">Route Details</div>
      <div class=" p-4 mb-12">
        <div fxLayout="column" fxFlex="100">
          <div fxLayout="row" fxLayoutAlign="space-between"  fxFlex="100" *ngFor="let route of routes; let i = index; let last = last">
            <div
              id="countdown-line" 
              fxLayout="column" 
              fxFlex="1"
            >
              <div class="border-dotted" *ngIf="!last">
                <div class="dot"></div>
                <mat-icon class="loc s-20 accent-500-fg fuse-white" >location_on</mat-icon>
              </div>
            </div>
            <div fxLayout="row" fxFlex="99" class="p-12 m-12 mt-0">
              <div fxLayout="column" fxFlex="10" fxLayoutAlign="center center" fxLayoutGap="12px" class="mr-12">
                  <div class="info-line" fxFlex fxLayoutAlign="center center">
                      <div class="title word-break responsive-font-small">
                          {{ (i+10).toString(36).toUpperCase() }}
                      </div>
                  </div>
              </div>
  
              <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-between stretch" fxLayoutGap="12px" class="m-12">
                <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                  <div fxLayout="column" fxFlex="50" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                    <div class="info-line">
                      <div class="info responsive-font-x-small mb-4">
                          Origin
                      </div>
                      <div class="title word-break responsive-font-small">
                          {{ (route?.originAddress ) || '-' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between stretch" fxLayoutGap="12px" class="mb-12">
                  <div fxLayout="column" fxFlex="50" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                    <div class="info-line">
                      <div class="info responsive-font-x-small mb-4">
                          Destination
                      </div>
                      <div class="title word-break responsive-font-small">
                          {{ (route?.destinationAddress ) || '-' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>

  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="start" class="p-24">
      <button mat-button class="blue-500" (click)="viewServiceRequest()">
        View Details
      </button>
    </div>
    <div fxLayoutAlign="end" class="p-24" fxLayoutGap="10px">
      <button mat-button class="grey-600" (click)="openRejectModal()">
        <mat-icon>
          clear
        </mat-icon>
        Reject
      </button>
      <button mat-button class="blue-500" (click)="acceptIncomming()">
        <mat-icon>check</mat-icon>
        Accept
      </button>
    </div>
  </div>
</div>