import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-no-trip-sos-modal',
  templateUrl: './no-trip-sos-modal.component.html',
  styleUrls: ['./no-trip-sos-modal.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class NoTripSosModalComponent implements OnInit {

  vehicle = [
  ]

  constructor(

    public matDialogRef: MatDialogRef<NoTripSosModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.vehicle.push(this._data.info)
   }

  ngOnInit(): void {
  }
  close(){
    this.dialog.closeAll();
  }
}
