import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "environments/environment";
import { BehaviorSubject } from "rxjs";
import { filter } from "lodash";

@Injectable({
  providedIn: "root",
})
export class FleetManagementService {
  private chasisGal = new BehaviorSubject(null);
  sendChasisGal = this.chasisGal.asObservable();

  private chasisLoad = new BehaviorSubject(null);
  sendchasisLoad = this.chasisLoad.asObservable();

  //share data from img to gallery
  private imgData = new BehaviorSubject(null);
  sendImgData = this.imgData.asObservable();

  //share data from gallery to parent
  private galleryData = new BehaviorSubject(null);
  sendGalleryData = this.galleryData.asObservable();

  constructor(private http: HttpClient) {}

  passImagesData(data: any) {
    this.galleryData.next(data);
  }

  passImgData(data: any) {
    this.imgData.next(data);
  }

  passChasisLoad(data: any[]) {
    this.chasisLoad.next(data);
  }

  passChasisGal(data: any[]) {
    this.chasisGal.next(data);
  }

  // Vehicle Services
  /**
   *
   * @param {string} truckingCompanyId
   * @param {number} [status]
   * @returns {Observable<any>}
   * @memberof FleetManagementService
   */
  getVehicleListing(truckingCompanyId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/${truckingCompanyId}/all`
    );
  }

  getVehicleListingSearch(
    truckingCompanyId,
    pageNumber,
    pageSize,
    orderBy,
    query
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle?truckingCompanyId=${truckingCompanyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&query=all:${query}`
    );
  }
  // progress
  getVehicleListingFilter(
    truckingCompanyId,
    pageNumber,
    pageSize,
    columnFilterValue
  ): Observable<any> {
    const params = [];

    for (const key in columnFilterValue) {
      if (columnFilterValue[key]) {
        params.push(`${key}:${columnFilterValue[key]}`);
      }
    }
    const url = "&query=" + params.join(",");
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle?truckingCompanyId=${truckingCompanyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&query=` +
        params.join(",")
    );
  }

  getVehicleDirverHistory(
    vehicleId,
    pageNumber,
    pageSize,
    orderBy,
    query
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/driver-assignment-history?vehicleId=${vehicleId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&query=all:${query}`
    );
  }

  getVehicleChassisHistory(
    vehicleId,
    pageNumberChassis,
    pageSizeChassis,
    orderByChassis,
    query
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/chassis-assignment-history?vehicleId=${vehicleId}&pageNumber=${pageNumberChassis}&pageSize=${pageSizeChassis}&orderBy=${orderByChassis}&query=all:${query}`
    );
  }

  addVehicle(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/`,
      payload
    );
  }

  updateVehicle(payload: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/`,
      payload
    );
  }
  getChassisTripHistory(id): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis/GetChassisTripHistoryPerChassis?chassisId=${id}`
    );
  }
  getVehicleTripHistory(vehicleId, columnFilterValue): Observable<any> {
    // getChassisListingFilter(): Observable<any> {
    //   // let fields = {
    //   //   brandModel      : columnFilterValue.brand,
    //   //   plateNumber     : columnFilterValue.plateNumber,
    //   //   type            : columnFilterValue.type,
    //   //   assignedVehicle : columnFilterValue.assigned,
    //   //   tripStatus      : columnFilterValue.tripStatus,
    //   //   status          : columnFilterValue.status,
    //   // }

    // const params = []

    // for (const key in columnFilterValue) {
    //   if (direction[key]) {
    //     params.push(direction)

    //   }
    // }
    // const url = "&IsDescending=" + params.join();

    let filter = [];
    for (const key in columnFilterValue) {
      columnFilterValue[key] = columnFilterValue[key].toString();
      if (columnFilterValue[key] != "") {
        filter.unshift(`${key}=${columnFilterValue[key]}`);
      }
    }

    const url = filter.join("&");
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetTripHistoryPerVehicle?vehicleId=${vehicleId}&` +
        url
    );
  }
  downloadVehicleTripHistory(
    title,
    vehicleId,
    columnFilterValue
  ): Observable<any> {
    let filter = [];
    let response;
    if (title == "excel") {
      response = "blob";
    } else {
      response = "text";
    }
    for (const key in columnFilterValue) {
      columnFilterValue[key] = columnFilterValue[key].toString();
      if (columnFilterValue[key] != "") {
        filter.unshift(`${key}=${columnFilterValue[key]}`);
      }
    }
    const url = filter.join("&");
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetTripHistoryPerVehicle/${title}?vehicleId=${vehicleId}&` +
        url,
      { responseType: "blob" as "json" }
    );
  }
  //
  deleteVehicle(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/${id}`
    );
  }
  updateVehicleStatus(payload, id): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/vehicle`,
      payload
    );
  }
  updateMultipleStatus(payload: any) {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/fleet/vehicle/status/update-multiple`,
      payload
    );
  }

  deleteMultiple(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/fleet/vehicle/delete-multiple`,
      payload
    );
  }
  viewVehicleId(id): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/${id}`
    );
  }

  addGroup(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/vehicle`,
      payload
    );
  }

  assignDriver(payload: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/vehicle`,
      payload
    );
  }

  assignChassis(payload: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/driver`,
      payload
    );
  }
  updateLoads(payload: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/`,
      payload
    );
  }
  updateLoadsList(payload: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/vchUpdateLoadType`,
      payload
    );
  }
  getVehicleCSV(TruckingCompanyId, Type) {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/file?TruckingCompanyId=${TruckingCompanyId}&Type=${Type}`,
      {
        headers,
        responseType: "text" as "json",
      }
    );
  }

  getVehicleExcel(TruckingCompanyId, Type) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/file?TruckingCompanyId=${TruckingCompanyId}&Type=${Type}`,
      {
        responseType: "blob" as "json",
      }
    );
  }
  getVehicleScorecards(truckingCompanyId) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/${truckingCompanyId}/scorecard`
    );
  }
  ////////////////////////////////////////////////////////////////////////////////////////////

  getVehicleDriverAssignmentHistory(
    vehicleId,
    pageNumberChassis,
    pageSizeChassis,
    orderByChassis,
    query
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/driver-assignment-history?vehicleId=${vehicleId}&pageNumber=${pageNumberChassis}&pageSize=${pageSizeChassis}&orderBy=${orderByChassis}&query=all:${query}`
    );
  }

  getVehicleAssignedDriverHistoryByColumnFilter(
    vehicleId,
    pageNumber,
    pageSize,
    orderBy,
    filteredValue
  ) {
    pageNumber = pageNumber + 1;

    const params = [];

    for (const key in filteredValue) {
      if (filteredValue[key]) {
        params.push(`${key}:${filteredValue[key]}`);
      }
    }

    const url = "&query=" + params.join(",");

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/driver-assignment-history?vehicleId=${vehicleId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&query=` +
        params.join(",")
    );
  }

  /**
   * Download Excel
   *
   * @param {*} vehicleId
   * @param {*} Type
   * @return {*}
   * @memberof FleetManagementService
   */
  getExcelVehicleDriverAssignment(vehicleId, type, timezoneOffset) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/driver-assignment-history/file?vehicleId=${vehicleId}&type=${type}&timezoneOffset=${timezoneOffset}`,
      {
        responseType: "blob" as "json",
      }
    );
  }

  /**
   * Download CSV
   *
   * @param {*} vehicleId
   * @param {*} Type
   * @return {*}
   * @memberof FleetManagementService
   */
  getCSVVehicleDriverAssignment(vehicleId, type, timezoneOffset) {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/driver-assignment-history/file?vehicleId=${vehicleId}&type=${type}&timezoneOffset=${timezoneOffset}`,
      {
        headers,
        responseType: "text" as "json",
      }
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getVehicleChassisAssignmentHistory(
    vehicleId,
    pageNumberChassis,
    pageSizeChassis,
    orderByChassis,
    query
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/chassis-assignment-history?vehicleId=${vehicleId}&pageNumber=${pageNumberChassis}&pageSize=${pageSizeChassis}&orderBy=${orderByChassis}&query=all:${query}`
    );
  }

  getVehicleAssignedChassisHistoryByColumnFilter(
    vehicleId,
    pageNumber,
    pageSize,
    orderBy,
    filteredValue
  ) {
    pageNumber = pageNumber + 1;

    const params = [];

    for (const key in filteredValue) {
      if (filteredValue[key]) {
        params.push(`${key}:${filteredValue[key]}`);
      }
    }

    const url = "&query=" + params.join(",");

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/chassis-assignment-history?vehicleId=${vehicleId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&query=` +
        params.join(",")
    );
  }

  /**
   * Download Excel
   *
   * @param {*} vehicleId
   * @param {*} Type
   * @return {*}
   * @memberof FleetManagementService
   */
  getExcelVehicleChassisAssignment(vehicleId, type, timezoneOffset) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/chassis-assignment-history/file?vehicleId=${vehicleId}&type=${type}&timezoneOffset=${timezoneOffset}`,
      {
        responseType: "blob" as "json",
      }
    );
  }

  /**
   * Download CSV
   *
   * @param {*} vehicleId
   * @param {*} Type
   * @return {*}
   * @memberof FleetManagementService
   */
  getCSVVehicleChassisAssignment(vehicleId, type, timezoneOffset) {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/chassis-assignment-history/file?vehicleId=${vehicleId}&type=${type}&timezoneOffset=${timezoneOffset}`,
      {
        headers,
        responseType: "text" as "json",
      }
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////

  driverListModalSearch(
    truckingCompanyId,
    pageNumber,
    pageSize,
    query
  ): Observable<any> {
    pageNumber = pageNumber + 1;

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/driver?truckingCompanyId=${truckingCompanyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&query=assignedVehicle:,searchAvailable:${query}`
    );
  }

  chassisListModalSearch(
    truckingCompanyId,
    pageNumber,
    pageSize,
    query
  ): Observable<any> {
    pageNumber = pageNumber + 1;

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle?truckingCompanyId=${truckingCompanyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&query=assignedChassis:,searchAvailable:${query}`
    );
  }

  vehicleListModalSearch(
    truckingCompanyId,
    pageNumber,
    pageSize,
    query
  ): Observable<any> {
    pageNumber = pageNumber + 1;

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis?truckingCompanyId=${truckingCompanyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&query=assignedVehicle:,searchAvailable:${query}`
    );
  }

  updateChassisVehicle(payload: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/chassis/`,
      payload
    );
  }

  updateVehicleDriver(payload: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/vehicle/`,
      payload
    );
  }

  addChassis(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/chassis`,
      payload
    );
  }

  viewChassisId(id): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis/${id}`
    );
  }
  updateChassisLoads(payload: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/chassis/`,
      payload
    );
  }

  deleteChassis(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.truckingService}/oat/api/v1/chassis/${id}`
    );
  }
  updateChassisStatus(payload, id): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/chassis`,
      payload
    );
  }
  chassisMultipleStatus(payload: any) {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/fleet/chassis/status/update-multiple`,
      payload
    );
  }
  deleteMultipleChassis(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/fleet/chassis/delete-multiple`,
      payload
    );
  }

  getChassisListing(truckingCompanyId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis/${truckingCompanyId}/all`
    );
  }

  getChassisListingSearch(
    truckingCompanyId,
    pageNumber,
    pageSize,
    orderBy,
    query
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis?truckingCompanyId=${truckingCompanyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&query=all:${query}`
    );
  }
  // progress
  getChassisListingFilter(
    truckingCompanyId,
    pageNumber,
    pageSize,
    orderBy,
    columnFilterValue
  ): Observable<any> {
    // let fields = {
    //   brandModel      : columnFilterValue.brand,
    //   plateNumber     : columnFilterValue.plateNumber,
    //   type            : columnFilterValue.type,
    //   assignedVehicle : columnFilterValue.assigned,
    //   tripStatus      : columnFilterValue.tripStatus,
    //   status          : columnFilterValue.status,
    // }

    const params = [];

    for (const key in columnFilterValue) {
      if (columnFilterValue[key]) {
        params.push(`${key}:${columnFilterValue[key]}`);
      }
    }
    const url = "&query=" + params.join(",");
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis?truckingCompanyId=${truckingCompanyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&query=` +
        params.join(",")
    );
  }

  assignDriverChassis(payload: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/chassis`,
      payload
    );
  }

  getChassisCSV(TruckingCompanyId, Type) {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis/file?TruckingCompanyId=${TruckingCompanyId}&Type=${Type}`,
      {
        headers,
        responseType: "text" as "json",
      }
    );
  }

  getChassisExcel(TruckingCompanyId, Type) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis/file?TruckingCompanyId=${TruckingCompanyId}&Type=${Type}`,
      {
        responseType: "blob" as "json",
      }
    );
  }

  updateChassis(payload: any): Observable<any> {
    return this.http.put<any>(
      `${environment.truckingService}/oat/api/v1/chassis/`,
      payload
    );
  }

  /**
   * Scorecards of Chassis Module
   *
   * @param {*} truckingCompanyId
   * @return {*}
   * @memberof FleetManagementService
   */
  getChassisScorecards(truckingCompanyId) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis/${truckingCompanyId}/scorecard`
    );
  }

  getChassisAssignedVehicleHistoryByColumnFilter(
    chassisId,
    pageNumber,
    pageSize,
    orderBy,
    filteredValue
  ) {
    pageNumber = pageNumber + 1;

    const params = [];

    for (const key in filteredValue) {
      if (filteredValue[key]) {
        params.push(`${key}:${filteredValue[key]}`);
      }
    }

    const url = "&query=" + params.join(",");

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis/assignment-history?chassisId=${chassisId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&query=` +
        params.join(",")
    );
  }

  /**
   * Download Excel
   *
   * @param {*} driverId
   * @param {*} Type
   * @return {*}
   * @memberof FleetManagementService
   */
  getExcelChassisAssignment(chassisId, type, timezoneOffset) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis/assignment-history/file?chassisId=${chassisId}&type=${type}&timezoneOffset=${timezoneOffset}`,
      {
        responseType: "blob" as "json",
      }
    );
  }

  /**
   * Download CSV
   *
   * @param {*} driverId
   * @param {*} Type
   * @return {*}
   * @memberof FleetManagementService
   */
  getCSVChassisAssignment(chassisId, type, timezoneOffset) {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/chassis/assignment-history/file?chassisId=${chassisId}&type=${type}&timezoneOffset=${timezoneOffset}`,
      {
        headers,
        responseType: "text" as "json",
      }
    );
  }

  /**
   *
   *
   * @returns {Observable<any>}
   * @memberof FleetManagementService
   */
  getDepotListing(truckingCompanyId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/depot/${truckingCompanyId}/all`
    );
  }

  //Driver Service

  /**
   *
   *
   * @returns {Observable<any>}
   * @memberof FleetManagementService
   */
  getDriverListingForVehicle(truckingCompanyId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/api/v1/driver/${truckingCompanyId}/all`
    );
  }

  /**
   * Get Driver Listing
   *
   * @returns {Observable<any>}
   * @memberof FleetManagementService
   */
  getDriverListing(truckingCompanyId): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/driver/${truckingCompanyId}/all`
    );
  }

  /**
   * Get Driver Listing by Column Filter
   *
   * @returns {Observable<any>}
   * @memberof FleetManagementService
   */
  getDriverListingByColumnFilter(
    truckingCompanyId,
    pageNumber,
    pageSize,
    orderBy,
    filteredValue
  ): Observable<any> {
    pageNumber = pageNumber + 1;

    const params = [];

    for (const key in filteredValue) {
      if (filteredValue[key]) {
        params.push(`${key}:${filteredValue[key]}`);
      }
    }

    const url = "&query=" + params.join(",");

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/driver?truckingCompanyId=${truckingCompanyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&query=` +
        params.join(",")
    );
  }

  /**
   * Add Driver
   *
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof FleetManagementService
   */
  addDriver(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/driver`,
      payload
    );
  }

  /**
   * Delete Driver
   *
   * @param {*} id
   * @returns {Observable<any>}
   * @memberof FleetManagementService
   */
  deleteDriver(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.truckingService}/oat/api/v1/driver/${id}`
    );
  }

  /**
   * View Driver
   *
   * @param {*} id
   * @returns {Observable<any>}
   * @memberof FleetManagementService
   */
  viewDriver(id): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/driver/${id}`
    );
  }

  /**
   * Edit Driver
   *
   * @returns {Observable<any>}
   * @memberof FleetManagementService
   */
  editDriver(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.truckingService}/oat/api/v1/driver`,
      payload
    );
  }

  /**
   * Download CSV
   *
   * @param {*} TruckingCompanyId
   * @param {*} Type
   * @return {*}
   * @memberof FleetManagementService
   */
  getCSV(TruckingCompanyId, Type) {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/driver/file?TruckingCompanyId=${TruckingCompanyId}&Type=${Type}`,
      {
        headers,
        responseType: "text" as "json",
      }
    );
  }

  /**
   * Download Excel
   *
   * @param {*} TruckingCompanyId
   * @param {*} Type
   * @return {*}
   * @memberof FleetManagementService
   */
  getExcel(TruckingCompanyId, Type) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/driver/file?TruckingCompanyId=${TruckingCompanyId}&Type=${Type}`,
      {
        responseType: "blob" as "json",
      }
    );
  }

  /**
   * Assign Vehicle to Driver
   *
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof FleetManagementService
   */
  getAssignVehicle(payload: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/driver`,
      payload
    );
  }

  /**
   * Get Assigned Vehicle
   *
   * @return {*}
   * @memberof FleetManagementService
   */
  getAssignedVehicleHistoryByColumnFilter(
    driverId,
    pageNumber,
    pageSize,
    orderBy,
    filteredValue
  ) {
    pageNumber = pageNumber + 1;

    const params = [];

    for (const key in filteredValue) {
      if (filteredValue[key]) {
        params.push(`${key}:${filteredValue[key]}`);
      }
    }

    const url = "&query=" + params.join(",");

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/driver/assignment-history?driverId=${driverId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&query=` +
        params.join(",")
    );
  }

  /**
   * Multiple Activate and Deactivate
   *
   * @param {*} payload
   * @return {*}
   * @memberof FleetManagementService
   */
  updateStatus(payload) {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/fleet/driver/status/update-multiple`,
      payload
    );
  }

  /**
   * Multiple Delete
   *
   * @param {*} payload
   * @return {*}
   * @memberof FleetManagementService
   */
  deleteMultipleDrivers(payload) {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/fleet/driver/-multiple`,
      payload
    );
  }

  /**
   * Used in updating Lock and Unlock Status in Driver View
   *
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof FleetManagementService
   */
  patchDriverDetails(payload: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/driver`,
      payload
    );
  }

  /**
   * Download Excel
   *
   * @param {*} driverId
   * @param {*} Type
   * @return {*}
   * @memberof FleetManagementService
   */
  getDriverExcelAssignment(driverId, type, timezoneOffset) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/driver/assignment-history/file?driverId=${driverId}&type=${type}&timezoneOffset=${timezoneOffset}`,
      {
        responseType: "blob" as "json",
      }
    );
  }

  /**
   * Download CSV
   *
   * @param {*} driverId
   * @param {*} Type
   * @return {*}
   * @memberof FleetManagementService
   */
  getDriverCSVAssignment(driverId, type, timezoneOffset) {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/driver/assignment-history/file?driverId=${driverId}&type=${type}&timezoneOffset=${timezoneOffset}`,
      {
        headers,
        responseType: "text" as "json",
      }
    );
  }

  /**
   * 1:1 assigning of vehicle to drivers
   *
   * @param {*} driverId
   * @param {*} pageNumber
   * @param {*} pageSize
   * @param {*} orderBy
   * @param {*} query
   * @return {*}  {Observable<any>}
   * @memberof FleetManagementService
   */
  getVehicleListingModalSearch(
    truckingCompanyId,
    pageNumber,
    pageSize,
    query
  ): Observable<any> {
    pageNumber = pageNumber + 1;

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/vehicle?truckingCompanyId=${truckingCompanyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&query=assignedDriver:,searchAvailable:${query}`
    );
  }

  /**
   * Scorecards of Driver Listing
   *
   * @param {*} truckingCompanyId
   * @return {*}
   * @memberof FleetManagementService
   */
  getDriverScorecards(truckingCompanyId) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/driver/${truckingCompanyId}/scorecard`
    );
  }

  /**
   * Used to toggle buttons in Driver Listing
   *
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof FleetManagementService
   */
  updateDriverStatus(payload): Observable<any> {
    return this.http.patch<any>(
      `${environment.truckingService}/oat/api/v1/driver`,
      payload
    );
  }

  /**
   * Fleet Management Audit Logs
   *
   * @param {*} fleetId
   * @param {*} pageNumber
   * @param {*} pageSize
   * @param {*} orderBy
   * @param {*} filteredValue
   * @return {*}
   * @memberof FleetManagementService
   */
  fleetAuditLogs(fleetId, pageNumber, pageSize, orderBy, filteredValue) {
    pageNumber = pageNumber + 1;

    const params = [];

    for (const key in filteredValue) {
      if (filteredValue[key]) {
        params.push(`${key}:${filteredValue[key]}`);
      }
    }

    const url = "&query=" + params.join(",");

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/fleet/audit-logs?fleetId=${fleetId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&query=` +
        params.join(",")
    );
  }

  /**
   * Download Excel
   *
   * @param {*} fleetId
   * @param {*} type
   * @return {*}
   * @memberof FleetManagementService
   */
  getFleetExcel(fleetId, type, timezoneOffset) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/fleet/audit-logs/file?fleetId=${fleetId}&type=${type}&timezoneOffset=${timezoneOffset}`,
      {
        responseType: "blob" as "json",
      }
    );
  }

  /**
   * Download CSV
   *
   * @param {*} fleetId
   * @param {*} type
   * @return {*}
   * @memberof FleetManagementService
   */
  getFleetCSV(fleetId, type, timezoneOffset) {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/fleet/audit-logs/file?fleetId=${fleetId}&type=${type}&timezoneOffset=${timezoneOffset}`,
      {
        headers,
        responseType: "text" as "json",
      }
    );
  }

  getTripTicketScoreCards(companyId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/tripticketlist/scorecard?truckingCompanyId=${companyId}`,
      {}
    );
  }
  getTripTicketsCSV(query): Observable<any> {
    let filter = [];
    for (const key in query) {
      if (query[key] != "") {
        filter.push(`${key}=${query[key]}`);
      }
    }

    const url = filter.join("&");
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetTripTicketsList/csv?${url}`,
      {
        headers,
        responseType: "text" as "json",
      }
    );
  }

  getTrucking(companyId, payload, filteredValue): Observable<any> {
    let pN = payload.pageNumber;

    let params = [];

    for (const key in filteredValue) {
      if (filteredValue[key]) {
        params.push(`&${key}=${filteredValue[key]}`);
      }
    }

    const { pageSize, isSOS, isException, isDescending } = payload;

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetTripTicketsList?TruckingCompanyId=${companyId}&pageNumber=${pN}&rowPerPage=${pageSize}&isSOS=${isSOS}&isException=${isException}&isDescending=${isDescending}` +
        params.join("")
    );
  }

  getTruckingDetails(jobOrderNo, jobOrder): Observable<any> {
    let params = new HttpParams()
      .set("TripTicketNo", jobOrder)
      .set("jobOrderNo", jobOrderNo);
    //   .set('query', payload.query)
    //   .set('pageNumber', payload.page + 1)
    //   .set('pageSize', payload.pageSize)
    //   .set('orderBy', payload.orderBy)

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetTripTicketDetails`,
      { params: params }
    );
  }

  /**
   * XLOG Driver App - Reset Pin
   *
   * @param {*} driverId
   * @param {*} body
   * @return {*}  {Observable<any>}
   * @memberof FleetManagementService
   */
  resetPin(driverId, body): Observable<any> {
    return this.http.put<any>(
      `${environment.truckingService}/oat/api/v1/driver/ResetPin?driverId=${driverId}`,
      body
    );
  }

  /**
   * Driver Trip History
   *
   * @param {*} driverId
   * @param {*} pageNumber
   * @param {*} rowPerPage
   * @param {*} filteredValue
   * @return {*}  {Observable<any>}
   * @memberof FleetManagementService
   */
  getDriverTripHistory(
    driverId,
    pageNumber,
    rowPerPage,
    filteredValue
  ): Observable<any> {
    const params = [];

    for (const key in filteredValue) {
      if (filteredValue[key]) {
        params.push(`&${key}=${filteredValue[key]}`);
      }
    }

    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/driver/GetDriverTripHistory?driverId=${driverId}&rowPerPage=${rowPerPage}&pageNumber=${pageNumber}` +
        params.join("")
    );
  }

  /**
   * Driver Trip History Excel
   *
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof FleetManagementService
   */
  getDriverTripHistoryExcel(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/DownloadServiceListToExcel`,
      payload,
      {
        responseType: "blob" as "json",
      }
    );
  }

  /**
   * Driver Trip History Excel
   *
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof FleetManagementService
   */
  getDriverTripHistoryCSV(payload): Observable<any> {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/DownloadServiceToCSV`,
      payload,
      {
        headers,
        responseType: "text" as "json",
      }
    );
  }

  getDriverQRCode(driverGuid): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/qrcode/create-driver-qrcode?driverGuid=${driverGuid}`,
      {}
    );
  }
}
