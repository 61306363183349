import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { RootState, selectUser } from "./core/store";
import { Store, select } from "@ngrx/store";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  private frame = document.getElementById("frame");
  private iframe;
  private user;

  constructor(private http: HttpClient, private store: Store<RootState>) {
    this.iframe = document.createElement("iframe");
    this.iframe.setAttribute("id", "middleware-iframe");
    this.iframe.style.display = "none";
    this.iframe.src = environment.ssoUrl;
    this.frame.appendChild(this.iframe);

    this.store
      .pipe(select(selectUser))
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.user = res.user;
        }
      });
  }

  /**
   * Gets Token on Initialization
   *
   * @returns
   * @memberof TokenService
   */
  getTokenOnInit() {
    return new Promise<any>((resolve, reject) => {
      const messageHandler = (event) => {
        const { action, key, value } = event.data;

        if (action === "returnData") {
          localStorage.setItem(key, JSON.stringify(value));

          if (
            localStorage.getItem("refreshToken") == "null" ||
            localStorage.getItem("refreshToken") == null
          ) {
            localStorage.setItem(
              "refreshToken",
              value?.cognito?.authenticationResult?.refreshToken
            );
          }
        }
        resolve("");
      };

      this.iframe.onload = () => {
        window.addEventListener("message", messageHandler, false);
        this.iframe.contentWindow.postMessage(
          {
            action: "get",
            key: "token",
          },
          "*"
        );
      };
    });
  }

  /**
   * Passes Token to SSO Domain
   *
   * @param {*} data
   * @returns
   * @memberof TokenService
   */
  setToken(data: any) {
    return new Promise<void>((resolve, reject) => {
      this.iframe.contentWindow.postMessage(
        {
          action: "save",
          key: "token",
          value: data,
        },
        "*"
      );
      resolve();
    });
  }

  /**
   * Get Token From SSO Domain
   *
   * @returns
   * @memberof TokenService
   */
  getToken() {
    return new Promise<any>((resolve, reject) => {
      const messageHandler = (event) => {
        const { action, key, value } = event.data;

        if (action === "returnData") {
          localStorage.setItem(key, JSON.stringify(value));
        }
        resolve("");
      };

      window.addEventListener("message", messageHandler, false);
      this.iframe.contentWindow.postMessage(
        {
          action: "get",
          key: "token",
        },
        "*"
      );
    });
  }

  /**
   * If Token is Expired, Get Another Token
   *
   * @returns
   * @memberof TokenService
   */
  generateNewToken() {
    let refreshToken = localStorage.getItem("refreshToken");
    return this.http.post(
      `${environment.authManagementAuthentication}/api/v1/auth/refresh/${
        this.user && this.user.username
      }`,
      {
        refreshToken,
      }
    );
  }
}
