import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  Event as NavigationEvent,
} from "@angular/router";
import { merge, Subject } from "rxjs";
import { filter, map, startWith, takeUntil } from "rxjs/operators";

import { FuseNavigationItem } from "@fuse/types";
import { fuseAnimations } from "@fuse/animations";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { select, Store } from "@ngrx/store";
import { RootState, selectCompany, selectException, selectUser } from "app/core/store";

@Component({
  selector: "fuse-nav-vertical-collapsable",
  templateUrl: "./collapsable.component.html",
  styleUrls: ["./collapsable.component.scss"],
  animations: fuseAnimations,
})
export class FuseNavVerticalCollapsableComponent implements OnInit, OnDestroy {
  @Input()
  item: FuseNavigationItem;

  @HostBinding("class")
  classes = "nav-collapsable nav-item";

  @HostBinding("class.open")
  public isOpen = false;

  private _countryId: any;
  private _countryName: any;
  // Private
  private _unsubscribeAll: Subject<any>;

  // KYC STATUS
  private __kycStatus: any;
  private _isNew: any;

  // Exception Status
  companyId: any;
  withException: boolean = false;
  exceptionScorecard: number = 0;

  currentRoute: any;

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {Router} _router
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseNavigationService: FuseNavigationService,
    private _router: Router,
    private store: Store<RootState>,
    private route: ActivatedRoute
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Get User Information
    this.store
      .pipe(select(selectUser))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          if (res) {
            this.__kycStatus = res.kyc.kycStatusCode;
            this._isNew = res.company.status;
            this.companyId = res.company.companyId;
          }
        },
        (err) => {
          console.warn(err);
        }
      );

    this.store
      .pipe(select(selectException))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this.withException = res?.withException;
          this.exceptionScorecard = res?.exceptionCount;
          this._router.events
            .pipe(
              filter((event) => event instanceof NavigationEnd),
              startWith(this._router)
            )
            .subscribe((event: NavigationEnd) => {
              this.currentRoute = event.url;
              let exception = JSON.parse(localStorage.getItem('exception'))
              this.withException = exception?.withException;
              this.exceptionScorecard = exception?.exceptionCount;
              if (this.withException) {
                // there will be first router.url - and next - router events
                if (this.currentRoute == '/main/transactions') {
                  this.addBadge("transaction-management");
                }
                if (this.currentRoute == '/main/transaction-management/trip-tickets') {
                  this.removeBadge("transaction-management");
                  this.addBadge("trip-tickets");
                }
                if (this.currentRoute == '/main/transaction-management/service-request') {
                  this.removeBadge("transaction-management");
                  this.addBadge("trip-tickets");
                }
                if (this.currentRoute != '/main/transactions' && this.currentRoute != '/main/transaction-management/trip-tickets' && this.currentRoute != '/main/transaction-management/service-request') {
                  this.addBadge("transaction-management");
                }
              } else {
                this.removeBadge("transaction-management");
                this.removeBadge("trip-tickets");
              }
            });
        },
        (err) => {
          console.warn(err);
        }
      );

    this.store
      .pipe(select(selectCompany))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          if (res) {
            const {
              country: {
                countryId,
                countryName
              }
            } = res
            this._countryId = countryId;
            this._countryName = countryName
          }
        },
        (err) => {
          console.warn(err);
        }
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Listen for router events
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((event: NavigationEnd) => {
        // Check if the url can be found in
        // one of the children of this item
        if (this.isUrlInChildren(this.item, event.urlAfterRedirects)) {
          this.expand();
        } else {
          this.collapse();
        }
      });

    // Listen for collapsing of any navigation item
    this._fuseNavigationService.onItemCollapsed
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((clickedItem) => {
        if (clickedItem && clickedItem.children) {
          // Check if the clicked item is one
          // of the children of this item
          if (this.isChildrenOf(this.item, clickedItem)) {
            return;
          }

          // Check if the url can be found in
          // one of the children of this item
          if (this.isUrlInChildren(this.item, this._router.url)) {
            return;
          }

          // If the clicked item is not this item, collapse...
          if (this.item !== clickedItem) {
            this.collapse();
          }
        }
      });

    // Check if the url can be found in
    // one of the children of this item
    if (this.isUrlInChildren(this.item, this._router.url)) {
      this.expand();
    } else {
      this.collapse();
    }

    // Subscribe to navigation item
    merge(
      this._fuseNavigationService.onNavigationItemAdded,
      this._fuseNavigationService.onNavigationItemUpdated,
      this._fuseNavigationService.onNavigationItemRemoved
    )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // setting badge for sidebar
    this.getData(this.item);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle collapse
   *
   * @param ev
   */
  toggleOpen(ev): void {
    ev.preventDefault();

    this.isOpen = !this.isOpen;

    // Navigation collapse toggled...
    this._fuseNavigationService.onItemCollapsed.next(this.item);
    this._fuseNavigationService.onItemCollapseToggled.next();
  }

  /**
   * Expand the collapsable navigation
   */
  expand(): void {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    this._fuseNavigationService.onItemCollapseToggled.next();
  }

  /**
   * Collapse the collapsable navigation
   */
  collapse(): void {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    this._fuseNavigationService.onItemCollapseToggled.next();
  }

  /**
   * Check if the given parent has the
   * given item in one of its children
   *
   * @param parent
   * @param item
   * @returns {boolean}
   */
  isChildrenOf(parent, item): boolean {
    const children = parent.children;

    if (!children) {
      return false;
    }

    if (children.indexOf(item) > -1) {
      return true;
    }

    for (const child of children) {
      if (child.children) {
        if (this.isChildrenOf(child, item)) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Check if the given url can be found
   * in one of the given parent's children
   *
   * @param parent
   * @param url
   * @returns {boolean}
   */
  isUrlInChildren(parent, url): boolean {
    const children = parent.children;

    if (!children) {
      return false;
    }

    for (const child of children) {
      if (child.children) {
        if (this.isUrlInChildren(child, url)) {
          return true;
        }
      }

      if (child.url === url || url.includes(child.url)) {
        return true;
      }
    }

    return false;
  }

  getData(data) {
    switch (data.id) {
      case "profile":
        this.checkKYCSatus(data);
        if (this.withException) {
          this.addBadge("transaction-management");
        } else {
          this.removeBadge("transaction-management");
          this.removeBadge("trip-tickets");
        }
        return;
      case "transaction-management":
        if (this.withException) {
          if ((this.currentRoute === '/main/transactions') && !this.isOpen) {
            this.addBadge("transaction-management");
          }
          if ((this.currentRoute === '/main/transactions') && this.isOpen) {
            this.removeBadge("transaction-management");
            this.addBadge("trip-tickets");
          }
        } else {
          this.removeBadge("transaction-management");
          this.removeBadge("trip-tickets");
        }
        return;
      default:
        this.setDefault();
        return;
    }
  }

  setDefault() {

    switch (this.__kycStatus) {
      case "APP":
      case "REJ":
        this.removeBadge("profile");
        break;
      default:
        this.addBadge("profile");
        break;
    }

    if (this.withException) {
      if (this.currentRoute == '/main/transaction-management/trip-tickets' || this.currentRoute == '/main/transaction-management/service-request') {
        this.removeBadge("transaction-management");
        this.addBadge("trip-tickets");
      } else {
        this.addBadge("transaction-management");
        this.removeBadge("trip-tickets");
      }
    } else {
      this.removeBadge("transaction-management");
      this.removeBadge("trip-tickets");
    }
    this.removeBadge("company-profile");
  }

  addBadge(data) {
    this._fuseNavigationService.updateNavigationItem(data, {
      badge: {
        title: data !== "trip-tickets" ? "!" : this.exceptionScorecard,
        fg: "fuse-white",
        bg: "red",
      },
    });
  }

  removeBadge(data) {
    this._fuseNavigationService.updateNavigationItem(data, {
      badge: null,
    });
  }

  checkKYCSatus(data) {
    switch (this.__kycStatus) {
      case "APP":
      case "REJ":
        this.removeBadge("profile");
        break;
      default:
        this.checkOpenNav(data);
        break;
    }
  }

  checkOpenNav(data) {
    if (this.isOpen) {
      this.addBadge("company-profile");
      this.removeBadge("profile");
      return;
    }

    this.addBadge("profile");
  }

  checker(id) {
    if (id === 'other-facilities') {
      if (this._countryId === 2 && this._countryName.toLowerCase() === 'malaysia') {
        return true;
      }
      return false;
    }
    return true;
  }
}
